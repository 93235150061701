<template>
  <Panel class="bordered trial-panel">
    <rbe-trial-panel-body class="flex-justified">
      <rbe-trial-description class="flex-top">
        <h1>Start your {{ trailDays }}&#8209;day free trial</h1>
        <p>Enable all premium add&#8209;ons for {{ trailDays }} days.</p>
      </rbe-trial-description>
      <button
        ref="start-trial-button"
        class="reedsy-accented button"
        type="button"
        @click="openPremiumModal"
      >
        Start {{ trailDays }}&#8209;day trial
      </button>
    </rbe-trial-panel-body>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IClientConfig} from '@reedsy/studio.shared/config';

@Component({
  components: {
    Panel,
  },
})
export default class TrialPanel extends ClientSharedVue {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInject('Config')
  public config: IClientConfig;

  public get trailDays(): number {
    return this.config.stripe.trialDays;
  }

  public openPremiumModal(): void {
    this.$subscriptionModal.open();
  }
}
</script>

<style lang="scss" scoped>
@include screen-less-than(sm) {
  rbe-trial-panel-body {
    flex-direction: column;
    gap: $space-base;
  }

  rbe-trial-description {
    align-items: center;
  }
}

.trial-panel {
  background-color: var(--local-color-muted);

  h1 {
    font-size: $font-size-lg;
    margin-bottom: $space-sm;

    @include font-family($controls, bold);
  }
}
</style>
