<template>
  <rbe-loading v-if="!offerings">
    <DelayedLoadingIndicator :delay="1_000" />
  </rbe-loading>
  <SubscriptionBaseModal
    v-else
    :id="id"
    :title="`Start your ${config.stripe.trialDays}-day trial`"
    subtitle="Studio offers a whole set of advanced writing and outlining features.
      All our add&#8209;ons also include a dark mode."
    class="subscription-trial-modal selector-modal"
  >
    <template #left>
      <FeatureSummaries
        feature-preview
        :prices="prices"
        interval="month"
      />
    </template>

    <template #action>
      <rbe-trial-actions>
        <button
          class="button"
          type="button"
          @click="close"
        >
          Not yet
        </button>

        <LoadingButton
          class="button"
          type="button"
          :loading="loading"
          @click="startTrial"
        >
          Start free trial
        </LoadingButton>
      </rbe-trial-actions>
    </template>
  </SubscriptionBaseModal>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import SubscriptionBaseModal from './subscription-base.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {setLoadingFlag} from '@reedsy/utils.disposable';
import {config} from '@reedsy/studio.shared/config';
import {IPriceOfferings, IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import DelayedLoadingIndicator from '@reedsy/studio.shared/components/loader/delayed-loading-indicator.vue';
import FeatureSummaries from './features/feature-summaries.vue';
import CurrencySelector from './currency-selector.vue';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';

@Component({
  components: {
    DelayedLoadingIndicator,
    LoadingButton,
    SubscriptionBaseModal,
    FeatureSummaries,
    CurrencySelector,
  },
})
export default class SubscriptionTrial extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public readonly config = config;
  public readonly cancelable = true;

  public loading = false;
  public offerings: IPriceOfferings = null;
  public selectedCurrency: SupportedCurrency = SupportedCurrency.USD;

  public get prices(): IPriceOptionsResponse {
    return {
      offerings: this.offerings,
      userCurrency: this.selectedCurrency,
    };
  }

  public async created(): Promise<void> {
    const {offerings, userCurrency} = await this.$subscription.fetchPrice();
    this.offerings = offerings;
    this.selectedCurrency = userCurrency;
  }

  public async startTrial(): Promise<void> {
    using doneLoading = setLoadingFlag(this, 'loading');
    await this.$subscription.startTrial(this.selectedCurrency);
    doneLoading();
    this.close();
    this.$modals.open('SubscriptionTrialStarted');
  }
}
</script>

<style lang="scss" scoped>
.subscription-trial-modal {
  rbe-trial-actions {
    margin-left: auto;
    display: flex;
    gap: $space-sm;

    @include screen-less-than(md) {
      flex-grow: 1;

      > button {
        flex-grow: 1;
      }
    }
  }
}
</style>
