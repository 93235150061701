import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-justified"
}
const _hoisted_2 = {
  key: 0,
  class: "left flex-top"
}
const _hoisted_3 = {
  key: 0,
  class: "left flex-justified"
}
const _hoisted_4 = {
  key: 1,
  class: "thin header-divider"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalCloseButton = _resolveComponent("ModalCloseButton")!
  const _component_PlainModal = _resolveComponent("PlainModal")!

  return (_openBlock(), _createBlock(_component_PlainModal, {
    id: _ctx.id,
    headless: true,
    class: _normalizeClass(["two-column-modal no-padding", {
      'attach-bottom mobile': _ctx.isMobile
    }])
  }, {
    default: _withCtx(() => [
      (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("rbe-header", _hoisted_1, [
            _createElementVNode("h1", null, [
              _renderSlot(_ctx.$slots, "title", {}, undefined, true)
            ]),
            _cache[1] || (_cache[1] = _createTextVNode()),
            _createVNode(_component_ModalCloseButton, {
              ref: "close-button",
              class: "close-button default-color plain with-shadow",
              onClick: _ctx.closeAll
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      _cache[7] || (_cache[7] = _createTextVNode()),
      _createElementVNode("rbe-columns", null, [
        (!_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("rbe-column", _hoisted_2, [
              (!_ctx.isMobile)
                ? (_openBlock(), _createElementBlock("rbe-header", _hoisted_3, [
                    _createElementVNode("h1", null, [
                      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                    ]),
                    _cache[2] || (_cache[2] = _createTextVNode()),
                    _createVNode(_component_ModalCloseButton, {
                      ref: "close-button",
                      class: "close-button default-color plain with-shadow",
                      onClick: _ctx.closeAll
                    }, null, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true),
              _cache[3] || (_cache[3] = _createTextVNode()),
              (!_ctx.isMobile)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
                : _createCommentVNode("", true),
              _cache[4] || (_cache[4] = _createTextVNode()),
              _createElementVNode("rbe-actions", null, [
                _renderSlot(_ctx.$slots, "left", {}, undefined, true)
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[5] || (_cache[5] = _createTextVNode()),
        _createElementVNode("rbe-column-contents", {
          class: _normalizeClass({'shadow': _ctx.displayShadow})
        }, [
          _createElementVNode("rbe-column", {
            ref: "scrollContainer",
            class: "right",
            onScrollPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateDisplayShadow && _ctx.updateDisplayShadow(...args)))
          }, [
            _renderSlot(_ctx.$slots, "right", {}, undefined, true)
          ], 544)
        ], 2),
        _cache[6] || (_cache[6] = _createTextVNode()),
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("rbe-actions", _hoisted_5, [
              _renderSlot(_ctx.$slots, "left", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["id", "class"]))
}