import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-middle" }
const _hoisted_2 = { ref: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddOnToggle = _resolveComponent("AddOnToggle")!
  const _component_SubscriptionStartedBase = _resolveComponent("SubscriptionStartedBase")!

  return (_openBlock(), _createBlock(_component_SubscriptionStartedBase, {
    id: _ctx.id,
    title: `Your ${_ctx.config.stripe.trialDays}-day trial has started`
  }, {
    banner: _withCtx(() => [
      _createElementVNode("rbe-trial-started", null, [
        _createElementVNode("rbe-toggles", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allAddonsTitles, (addOnTitle) => {
            return (_openBlock(), _createBlock(_component_AddOnToggle, {
              key: addOnTitle,
              title: addOnTitle,
              "model-value": true,
              disabled: true
            }, null, 8, ["title"]))
          }), 128))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createElementVNode("span", _hoisted_2, "\n      Now’s your chance to develop a writing routine and explore our outlining features. Happy writing!\n    ", 512)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}