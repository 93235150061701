
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import TrialCountdownMixin from '@reedsy/studio.shared/mixins/subscriptions/trial-countdown';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import PremiumBadge from './premium-badge.vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';

@Component({
  components: {
    PremiumBadge,
    ExpandTransition,
  },
})
export default class PremiumAdBanner extends mixins(ClientSharedVue, TrialCountdownMixin) {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInject('Navigation')
  public $navigation: INavigation;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public get message(): string {
    if (this.$subscription.isTrial) {
      return this.trialMessage;
    }

    return 'Explore our premium add-ons';
  }

  public get badgeText(): string {
    if (!this.$subscription.hasEverHadSubscription) {
      return 'Start free trial';
    }

    return 'Upgrade';
  }

  public get shouldShow(): boolean {
    if (!this.$subscription.hasCurrentSubscriptionBeenInitialised) return false;
    if (this.$subscription.currentSubscriptionInfo?.isCancelling) return true;
    if (this.$subscription.isTrial) {
      return !this.$subscription.hasPaymentMethodSet;
    }

    return !this.$subscription.hasAnyPaidFeature;
  }

  public openPremiumModal(): void {
    this.$subscriptionModal.open();
  }
}
