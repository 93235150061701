import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex-justified" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeriodSummary = _resolveComponent("PeriodSummary")!

  return (_openBlock(), _createElementBlock("rbe-subscription-period", _hoisted_1, [
    (!_ctx.disableMonthly)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createVNode(_component_PeriodSummary, {
            class: "period-option",
            selected: !_ctx.billAnnually,
            interval: "month"
          }, null, 8, ["selected"]),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _withDirectives(_createElementVNode("input", {
            ref: "month-input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billAnnually) = $event)),
            type: "radio",
            value: false,
            "aria-label": "Monthly"
          }, null, 512), [
            [_vModelRadio, _ctx.billAnnually]
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createElementVNode("label", null, [
      _createVNode(_component_PeriodSummary, {
        class: "period-option",
        selected: _ctx.billAnnually,
        interval: "year"
      }, null, 8, ["selected"]),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _withDirectives(_createElementVNode("input", {
        ref: "year-input",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.billAnnually) = $event)),
        type: "radio",
        value: true,
        "aria-label": "Annually"
      }, null, 512), [
        [_vModelRadio, _ctx.billAnnually]
      ])
    ])
  ]))
}