import {VuexModule, Mutation, Action} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {injectable} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {Store} from 'vuex';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {reactiveDate} from '@reedsy/studio.shared/utils/vue/reactive-date';
import {ISubscriptionTrialEndDateResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-subscription-trial-end-date';
import {ISubscriptionIntentClientSecretResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-subscription-intent-client-secret-response';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {IPaidFeature, IPaidFeatures, SubscriptionProduct} from '@reedsy/utils.subscription';
import {objectKeys, objectValues} from '@reedsy/utils.object';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {ICurrentSubscription} from './current-subscription.interface';
import {deduplicateConcurrentCalls, memoize} from '@reedsy/utils.decorator';
import shortDateWithYear from '@reedsy/studio.shared/filters/short-date-with-year';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import {dig} from '@reedsy/utils.dig';
import {isCancellingSubscription} from './is-cancelling-subscription';
import {ICreateDraftSubscription} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/ensure-subscription';
import {IStartTrialRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/start-trial-request';
import {ICalculatePriceRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/calculate-price-request';
import {IUpdateSubscriptionRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/update-subscription-request';
import {ISubscriptionInfoResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-subscription-info-response';

@injectable()
export class SharedSubscriptionModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,
  ) {
    @Module({name: SharedStoreName.Subscription, store})
    class Subscription extends VuexModule {
      public paidFeatures: IPaidFeatures = null;
      public trialEnd: Date = null;
      private currentSubscription: ISubscriptionInfoResponse = null;

      public get hasAnyPaidFeature(): boolean {
        return !!objectValues(this.paidFeatures || {}).find(Boolean);
      }

      public get hasCurrentSubscriptionBeenInitialised(): boolean {
        return !!this.currentSubscription;
      }

      public get isTrial(): boolean {
        const now = reactiveDate().value;
        return this.trialEnd > now;
      }

      public get hasEverHadSubscription(): boolean {
        return !!this.trialEnd;
      }

      public get hasPaymentMethodSet(): boolean {
        return !!this.currentSubscriptionInfo?.hasPaymentMethodSet;
      }

      public get hasFeature() {
        return (feature: IPaidFeature) => !!this.paidFeatures?.[feature];
      }

      public get activeProducts(): Set<SubscriptionProduct> {
        const products = objectKeys(
          dig(this.currentSubscription, 'info', 'currentPeriod', 'activeProducts') || {},
        );

        return new Set(products);
      }

      public get currentSubscriptionInfo(): ICurrentSubscription {
        const subscriptionInfo = this.currentSubscription;
        if (!subscriptionInfo?.hasActiveSubscription) return null;

        const {currentPeriod, nextBilling, currency} = subscriptionInfo.info;
        const products = new Set(objectKeys(dig(currentPeriod, 'activeProducts')));
        const nextBillingProducts = new Set(objectKeys(dig(nextBilling, 'activeProducts') || {}));

        const isCancelling = isCancellingSubscription(nextBilling);
        const nextBillingPrice = dig(nextBilling, 'amount') || null;
        const interval = dig(nextBilling, 'interval') || dig(currentPeriod, 'interval');
        const nextBillingDate = shortDateWithYear(new Date(nextBilling.date), 'numeric');

        return {
          currency,
          interval,
          products,
          isCancelling,
          isTrail: currentPeriod.isTrial,
          hasPaymentMethodSet: currentPeriod.hasPaymentMethodSet,
          nextBilling: {
            price: nextBillingPrice,
            date: nextBillingDate,
            products: nextBillingProducts,
          },
        };
      }

      @Mutation
      public PAID_FEATURES(paidFeatures: IPaidFeatures): void {
        this.paidFeatures = paidFeatures || {};
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        await this.refreshCurrentSubscriptionInfo();
      }

      @Action
      public async fetchTrialEnd(): Promise<void> {
        if (this.trialEnd) return;
        const {trialEndDate} = await api.get<ISubscriptionTrialEndDateResponse>('/subscription/trial/end-date');
        this.TRIAL_END(trialEndDate);
      }

      @Action
      public async startTrial(currency: SupportedCurrency): Promise<void> {
        if (this.trialEnd) return;
        const payload: IStartTrialRequest = {currency};
        await api.post('subscription/trial', payload);
      }

      @Action
      public async fetchIntentClientSecret(): Promise<
        Pick<ISubscriptionIntentClientSecretResponse, 'type' | 'clientSecret'>
      > {
        const {clientSecret, type} = await this.getIntentClientSecret();
        return {clientSecret, type};
      }

      @Action
      public async updateSubscription(update: IUpdateSubscriptionRequest): Promise<void> {
        return api.patch('/subscription/', update);
      }

      @Action
      @deduplicateConcurrentCalls()
      public async refreshCurrentSubscriptionInfo(): Promise<void> {
        const response = await api.fetchCurrentSubscriptionInfo();
        this.CURRENT_SUBSCRIPTION(response);
      }

      @Action
      public async cancelSubscription(): Promise<void> {
        await api.delete('/subscription/');
        await this.refreshCurrentSubscriptionInfo();
      }

      @Action
      @memoize
      public async calculateSubscriptionPrice(request: ICalculatePriceRequest): Promise<ICalculatePriceResponse> {
        return api.calculatePrice(request);
      }

      @Action
      @memoize
      public async fetchPrice(): Promise<IPriceOptionsResponse> {
        return api.get('/subscription/price/options');
      }

      @Action
      public async createDraftSubscription(data: ICreateDraftSubscription): Promise<void> {
        await api.post('/subscription/', data);
      }

      @Mutation
      private TRIAL_END(date: Date): void {
        this.trialEnd = date;
      }

      @Action
      private async getIntentClientSecret(): Promise<ISubscriptionIntentClientSecretResponse> {
        return api.get('/subscription/intent/client-secret');
      }

      @Mutation
      private CURRENT_SUBSCRIPTION(currentSubscription: ISubscriptionInfoResponse): void {
        this.currentSubscription = currentSubscription;
      }
    }

    this.Module = Subscription;
  }
}

export type SharedSubscriptionModule = InstanceType<SharedSubscriptionModuleFactory['Module']>;
