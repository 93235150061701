import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumIcon = _resolveComponent("PremiumIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`font-size-${_ctx.size}`)
  }, [
    _createElementVNode("rbe-premium-badge", null, [
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _cache[0] || (_cache[0] = _createTextVNode("\n          Premium\n        "))
        ], true)
      ]),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createVNode(_component_PremiumIcon, { size: _ctx.size }, null, 8, ["size"])
    ])
  ], 2))
}