import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    confirm: {
      label: 'Cancel subscription',
      handler: _ctx.cancelSubscription,
    },
    title: "Cancel subscription",
    class: "danger"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("p", null, "\n      Are you sure you want to cancel your subscription?\n      You’ll retain access to your add-ons until the end of your current billing period.\n      After that, they will be removed from your account.\n    ", -1)
    ])),
    _: 1
  }, 8, ["id", "confirm"]))
}