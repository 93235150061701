<template>
  <PlainModal
    :id="id"
    :headless="true"
    class="two-column-modal no-padding"
    :class="{
      'attach-bottom mobile': isMobile
    }"
  >
    <rbe-header
      v-if="isMobile"
      class="flex-justified"
    >
      <h1>
        <slot name="title" />
      </h1>
      <ModalCloseButton
        ref="close-button"
        class="close-button default-color plain with-shadow"
        @click="closeAll"
      />
    </rbe-header>

    <rbe-columns>
      <rbe-column
        v-if="!isMobile"
        class="left flex-top"
      >
        <rbe-header
          v-if="!isMobile"
          class="left flex-justified"
        >
          <h1>
            <slot name="title" />
          </h1>
          <ModalCloseButton
            ref="close-button"
            class="close-button default-color plain with-shadow"
            @click="closeAll"
          />
        </rbe-header>
        <hr
          v-if="!isMobile"
          class="thin header-divider"
        >

        <rbe-actions>
          <slot name="left" />
        </rbe-actions>
      </rbe-column>

      <rbe-column-contents :class="{'shadow': displayShadow}">
        <rbe-column
          ref="scrollContainer"
          class="right"
          @scroll.passive="updateDisplayShadow"
        >
          <slot name="right" />
        </rbe-column>
      </rbe-column-contents>

      <rbe-actions v-if="isMobile">
        <slot name="left" />
      </rbe-actions>
    </rbe-columns>
  </PlainModal>
</template>

<script lang="ts">
import {Component, mixins, Prop, Ref} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ModalCloseButton from '@reedsy/studio.shared/components/modals/components/modal-close-button.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';

@Component({
  components: {
    ModalCloseButton,
    PlainModal,
  },
})
export default class TwoColumnModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Browser)
  public readonly $browser: SharedBrowserModule;

  @Prop({type: String, default: ''})
  public title: string;

  @Ref('scrollContainer')
  private scrollContainer: HTMLElement;

  private displayShadow = false;
  private resizeObserver = new ResizeObserver(this.updateDisplayShadow);

  public get isMobile(): boolean {
    return this.$browser.screen.lessThan.md;
  }

  public mounted(): void {
    this.resizeObserver.observe(this.scrollContainer);
  }

  public beforeUnmount(): void {
    this.resizeObserver.disconnect();
  }

  public closeAll(): void {
    this._sharedModals.CLOSE_ALL();
  }

  public updateDisplayShadow(): void {
    const {scrollTop, scrollHeight, clientHeight} = this.scrollContainer;
    this.displayShadow = Math.ceil(scrollTop + clientHeight) < scrollHeight;
  }
}
</script>

<style lang="scss" scoped>
.two-column-modal {
  --column-padding: #{$space-xl};
  --right-column-background: var(--reedsy-neutral-muted);

  width: min(var(--modal-max-width, 70rem), 100vw);
  overflow: hidden;
  position: relative;

  &.mobile {
    --column-padding: #{$space-base};

    rbe-header {
      @include font-family($controls, bold);

      flex: 0 0 2rem;
      padding: $space-md var(--column-padding);
      font-size: $font-size-lg;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: $border;
      position: sticky;
      top: 0;
      background-color: var(--reedsy-plain);
      z-index: 9999;
    }

    rbe-actions {
      background-color: var(--reedsy-plain);
      padding: var(--column-padding);
    }

    &:not(.equal-columns) rbe-actions {
      position: sticky;
      bottom: 0;
      box-shadow: $box-shadow-up-base;
    }
  }

  &:not(.mobile) {
    rbe-column-contents {
      position: relative;

      &.shadow::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4rem;
        background: linear-gradient(to top, var(--reedsy-neutral), transparent);
      }
    }

    rbe-columns rbe-column {
      max-height: calc(var(--modal-max-height) - 2 * var(--column-padding));
      overflow-y: auto;
    }
  }

  &.equal-columns {
    rbe-columns {
      grid-auto-columns: 1fr 1fr;
    }

    &.mobile rbe-column-contents {
      order: 1;
      border-top: $border;
    }
  }

  .header-divider {
    margin: $space-xl 0;
  }

  rbe-columns {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr 2fr;

    rbe-column {
      position: relative;
      padding: var(--column-padding);
    }
  }

  h1 {
    @include font-family($controls, bold);

    display: flex;
    align-items: center;
    gap: $space-md;
    justify-content: left;
    font-size: $font-size-xxl;
  }

  .left {
    align-items: stretch;

    hr {
      width: 100%;
    }
  }

  .right {
    background: var(--right-column-background);
  }

  rbe-actions {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @include screen-less-than(md) {
    rbe-columns {
      grid-auto-flow: row;
      grid-template-columns: 1fr;
    }
  }
}
</style>
