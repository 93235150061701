import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconCrown = _resolveComponent("VuiIconCrown")!

  return (_openBlock(), _createElementBlock("rbe-premium-icon", null, [
    _createVNode(_component_VuiIconCrown, {
      class: _normalizeClass(`icon-circle icon-${_ctx.size}`)
    }, null, 8, ["class"])
  ]))
}