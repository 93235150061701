
import {Component, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import PeriodToggle from '@reedsy/studio.shared/components/modals/components/subscription/features/period-toggle.vue';
import {FEATURE_SUMMARIES} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';
import DiscountAmount from '@reedsy/studio.shared/components/modals/components/subscription/discount-amount.vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {deepEqual} from '@reedsy/utils.deep-equal';
import {objectKeys} from '@reedsy/utils.object';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import DelayedLoadingIndicator from '@reedsy/studio.shared/components/loader/delayed-loading-indicator.vue';
import SubscriptionPriceBreakdown from '@reedsy/studio.shared/components/subscriptions/subscription-price-breakdown.vue';
import SubscriptionPrice from '@reedsy/studio.shared/components/subscriptions/subscription-price.vue';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {DEFAULT_BILLING_INTERVAL} from '@reedsy/studio.shared/components/modals/components/subscription/features/default-billing-interval';
import FeatureSummary from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summary.vue';
import {IBillingInterval} from '@reedsy/utils.subscription';

@Component({
  components: {
    PeriodToggle,
    FeatureSummary,
    DiscountAmount,
    DelayedLoadingIndicator,
    SubscriptionPriceBreakdown,
    SubscriptionPrice,
  },
})
export default class ManageAddonsPanel extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  public readonly availableSummaries = FEATURE_SUMMARIES.filter((summary) => summary.product);

  public loading = true;
  public selectedInterval: IBillingInterval = DEFAULT_BILLING_INTERVAL;
  public activeAddons: Partial<Record<SubscriptionProduct, boolean>> = {};
  public calculatedPrice: ICalculatePriceResponse = null;
  public prices: IPriceOptionsResponse = null;

  public get currentSubscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get isTrial(): boolean {
    return this.$subscription.isTrial;
  }

  public get hasActiveYearlySubscription(): boolean {
    return this.currentSubscription?.interval === 'year';
  }

  public get selectedAddons(): Set<SubscriptionProduct> {
    return new Set(objectKeys(this.activeAddons).filter((product) => this.activeAddons[product]));
  }

  public get isAddingAddon(): boolean {
    return !Array.from(this.selectedAddons).every((product) => {
      return this.currentSubscription.products.has(product);
    });
  }

  public get nextPeriodSelectedAddons(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.nextBilling.products);
  }

  public get billAnnually(): boolean {
    return this.selectedInterval === 'year';
  }

  public set billAnnually(billAnnually: boolean) {
    this.selectedInterval = billAnnually ? 'year' : 'month';
  }

  public get activeFeaturesHaveChanged(): boolean {
    return !deepEqual(this.nextPeriodSelectedAddons, this.selectedAddons);
  }

  public get billingPeriodHasChanged(): boolean {
    const previousInterval = this.currentSubscription ? this.currentSubscription.interval : DEFAULT_BILLING_INTERVAL;
    return this.selectedInterval !== previousInterval;
  }

  public get hasSubscriptionChanged(): boolean {
    return this.activeFeaturesHaveChanged || this.billingPeriodHasChanged;
  }

  public get showPriceBreakdown(): boolean {
    return !!this.selectedAddons.size;
  }

  public get cancelling(): boolean {
    return this.currentSubscription && !this.selectedAddons.size;
  }

  public get cancelled(): boolean {
    return this.currentSubscription.isCancelling;
  }

  public async created(): Promise<void> {
    [, this.prices] = await Promise.all([
      this.$subscription.refreshCurrentSubscriptionInfo(),
      this.$subscription.fetchPrice(),
    ]);

    this.loading = false;

    if (!this.currentSubscription) return;
    this.selectedInterval = this.currentSubscription.interval;
    this.currentSubscription.products.forEach((key: SubscriptionProduct) => {
      this.activeAddons[key] = true;
    });
  }

  public continueToPayment(): void {
    this.$subscriptionModal.openPayment({
      price: this.calculatedPrice,
    });
  }

  @Watch('selectedInterval')
  @Watch('activeAddons', {deep: true})
  public async subscriptionUpdated(): Promise<void> {
    this.calculatedPrice = null;
    if (!this.selectedAddons.size) return;

    const interval = this.selectedInterval;
    const products = [...this.selectedAddons];
    this.calculatedPrice = await this.$subscription.calculateSubscriptionPrice({
      interval,
      products,
    });
  }

  public openConfirmCancelModal(): void {
    this.$subscriptionModal.openCancelConfirmation();
  }
}
