import {ILoadingTimingRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/timing/loading-timing-request';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {injectable} from 'inversify';

const logger = loggerFactory.create('TimingReporter');

@injectable()
export class TimingReporter {
  @$inject('Api')
  public readonly _api: IApi;

  public report(name: keyof ILoadingTimingRequest): void {
    if (window.performance.getEntriesByName(name).length) {
      return logger.debug('Ignoring duplicate timing event', {data: {name}});
    }

    window.performance.mark(name);
    this.flush(name);
  }

  private async flush(name: keyof ILoadingTimingRequest): Promise<void> {
    const measure = window.performance.measure(name, {}, name);

    try {
      await this._api.submitTimings({[name]: measure.duration});
    } catch (error) {
      logger.debug('Failed to report timings', {error});
      // We could potentially try to recover here, or put the request
      // back into pending, but when we do that, our metric starts to
      // get stale, which may be misleading
    }
  }
}
