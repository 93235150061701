import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumBadge = _resolveComponent("PremiumBadge")!
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!

  return (_openBlock(), _createBlock(_component_ExpandTransition, null, {
    default: _withCtx(() => [
      (_ctx.shouldShow)
        ? (_openBlock(), _createElementBlock("rbe-premium-ad-banner-wrapper", _hoisted_1, [
            _createElementVNode("button", {
              ref: "open-premium-modal-button",
              class: "premium-ad-banner",
              type: "button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPremiumModal && _ctx.openPremiumModal(...args)))
            }, [
              _createElementVNode("span", { ref: "message" }, _toDisplayString(_ctx.message), 513),
              _cache[1] || (_cache[1] = _createTextVNode()),
              _createVNode(_component_PremiumBadge, {
                size: "xs",
                class: "premium-badge"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.badgeText), 1)
                ]),
                _: 1
              })
            ], 512)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}