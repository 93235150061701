<template>
  <button
    type="button"
    class="button button-full reedsy-accented product-button button-sm"
    :class="{
      'bordered neutral': !selected,
    }"
    @click.stop="selected = !selected"
  >
    {{ selected ? 'Add-on selected' : 'Select add-on' }}
    <VuiToggle
      class="borderless"
      :model-value="selected"
    />
  </button>
</template>

<script lang="ts">
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class FeatureToggle extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;
}
</script>

<style lang="scss" scoped>
.product-button {
  display: flex;
  justify-content: space-between;

  .vui-toggle.borderless {
    :deep(.toggle) {
      --local-color-default: var(--studio-subscriptions-featureSummary-toggleHandle-color);

      border-color: transparent;
      background-color: var(--studio-subscriptions-featureSummary-toggle-backgroundColor);
    }

    &.checked {
      &:hover :deep(.toggle) {
        background-color: var(--studio-subscriptions-featureSummary-toggle-backgroundColor-selected-hover);
      }

      :deep(.toggle) {
        background-color: var(--studio-subscriptions-featureSummary-toggle-backgroundColor-selected);
      }
    }
  }
}
</style>
