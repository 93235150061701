
import {Component, Emit} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import MainLayout from '@reedsy/studio.home.bookshelf/components/layouts/main-layout.vue';
import {ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING} from '@reedsy/studio.home.bookshelf/utils/account-settings-route-name-mapping';
import {objectKeys} from '@reedsy/utils.object';

@Component({
  components: {
    MainLayout,
    AccountSettingsMenu,
  },
})
export default class AccountSettingsMenu extends BookshelfVue {
  public readonly settingsRouteNames = objectKeys(ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING);

  public get items(): any[] {
    return this.settingsRouteNames
      .map((routeName) => {
        return {
          routeName,
          title: ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING[routeName],
        };
      });
  }

  @Emit('open-details')
  public emitOpenDetails(): void {
    return;
  }
}
