<template>
  <VuiToggle
    ref="toggle"
    v-model="notifications"
    class="label-left spread"
    :label="goal.title"
    :disabled="!enabled || null"
  />
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IUserWordCountGoal} from '@reedsy/reedsy-sharedb/lib/common/word-count-goal/user-word-count-goal';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookActionsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-actions/book-actions';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';

@Component({})
export default class GoalToggle extends BookshelfVue {
  @Prop({type: Object})
  public goal: IUserWordCountGoal;

  @$lazyInjectStore(StoreName.BookActions)
  public readonly bookActions: BookActionsModule;

  @$lazyInjectStore(StoreName.CurrentBook)
  public readonly book: CurrentBookModule;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public readonly subscription: SharedSubscriptionModule;

  public get enabled(): boolean {
    return this.subscription.hasFeature('userWordCountGoals');
  }

  public get notifications(): boolean {
    return this.goal.notifications;
  }

  public set notifications(value: boolean) {
    this.bookActions.changeUserGoalNotification({
      goalId: this.goal._id,
      bookId: this.book.id,
      notifications: value,
    });
  };
}
</script>
