
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IClientConfig} from '@reedsy/studio.shared/config';

@Component({
  components: {
    Panel,
  },
})
export default class TrialPanel extends ClientSharedVue {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInject('Config')
  public config: IClientConfig;

  public get trailDays(): number {
    return this.config.stripe.trialDays;
  }

  public openPremiumModal(): void {
    this.$subscriptionModal.open();
  }
}
