import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlainModal = _resolveComponent("PlainModal")!

  return (_openBlock(), _createBlock(_component_PlainModal, {
    id: _ctx.id,
    headless: true,
    class: "subscription-started-modal accent-premium extra-wide no-padding"
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-subscription-started", null, [
        _renderSlot(_ctx.$slots, "banner", {}, undefined, true),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createElementVNode("p", null, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createElementVNode("button", {
          class: "button reedsy-accented",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, "\n        Let’s go!\n      ")
      ])
    ]),
    _: 3
  }, 8, ["id"]))
}