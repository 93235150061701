import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable} from 'inversify';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {Store} from 'vuex';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {IInvitationInfo} from '@reedsy/studio.isomorphic/controllers/api/v1/books/invitations/i-invitation-info';
import {HttpStatusCode} from 'axios';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import {digErrorCode} from '@reedsy/studio.shared/utils/errors/dig-error-code';
import {
  InvitationExpiredError,
  InvitationAlreadyAcceptedError,
  InvitationRevokedError,
} from '@reedsy/studio.isomorphic/errors/collaboration';
import {oneLine} from 'common-tags';
import Notify from '@reedsy/studio.shared/services/notify/notify';

export const ERROR_CODES_MAPPING = {
  [InvitationExpiredError.name]: oneLine`
    The invitation has expired. Please contact the book owner for a new invitation.
  `,
  [InvitationAlreadyAcceptedError.name]: oneLine`
    The invitation has already been accepted. Please contact the book owner for a new invitation.
  `,
  [InvitationRevokedError.name]: oneLine`
    The invitation has been revoked. Please contact book owner for a new invitation.
  `,
};

@injectable()
export class InvitationApprovalFlowModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,

    @$inject('Navigation')
    navigation: INavigation,
  ) {
    @Module({name: StoreName.InvitationApprovalFlow, store})
    class InvitationApprovalFlow extends VuexModule {
      public invitationData: IInvitationInfo = null;
      public bookId: string = null;
      public invitationId: string = null;

      public get isLoading(): boolean {
        return !this.invitationData;
      }

      public get bookUuid(): string {
        return this.invitationData?.book.uuid || null;
      }

      @Action
      @NotifyError('Cannot load invitation. Please contact support team.')
      public async initialiseInvitation(data: {invitationId: string; bookId: string}): Promise<void> {
        const {bookId, invitationId} = data;

        try {
          const invitationData = await api.fetchInvitationInfo(data);
          this.SET_FLOW_DATA({
            bookId,
            invitationId,
            invitationData,
          });
        } catch (error) {
          if (error.response?.status === HttpStatusCode.NotFound) {
            return navigation.goToNotFoundPage();
          }
          throw error;
        }
      }

      @Action
      @NotifyError('Cannot accept invitation, if problem persists please contact our support team')
      public async acceptInvitation(): Promise<void> {
        try {
          await api.acceptInvitation({
            invitationId: this.invitationId,
            bookId: this.bookId,
          });
          await navigation.goToBook(this.bookUuid);
        } catch (error) {
          const errorCode = digErrorCode(error);
          const message = ERROR_CODES_MAPPING[errorCode];
          if (!message) throw error;
          Notify.error({message});
        }
      }

      @Action
      @NotifyError('Something went wrong, we cannot log you out.')
      public async changeAccount(): Promise<void> {
        const afterSignInPath = navigation.buildAcceptInvitationUrl({
          invitationId: this.invitationId,
          bookId: this.bookId,
        });

        await api.signOut();
        await navigation.goToSignIn({afterSignInPath});
      }

      @Mutation
      public CLEAN(): void {
        this.invitationData = null;
        this.bookId = null;
        this.invitationId = null;
      }

      @Mutation
      private SET_FLOW_DATA(
        {bookId, invitationId, invitationData}:
        {bookId: string; invitationId: string; invitationData: IInvitationInfo},
      ): void {
        this.bookId = bookId;
        this.invitationId = invitationId;
        this.invitationData = invitationData;
      }
    }

    this.Module = InvitationApprovalFlow;
  }
}

export type InvitationApprovalFlowModule = InstanceType<InvitationApprovalFlowModuleFactory['Module']>;
