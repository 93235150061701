import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "reedsy-accented accent-premium flex-top" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentInfoPanel = _resolveComponent("PaymentInfoPanel")!
  const _component_ManageAddonsPanel = _resolveComponent("ManageAddonsPanel")!
  const _component_TrialPanel = _resolveComponent("TrialPanel")!

  return (_openBlock(), _createElementBlock("rbe-subscription-account-settings", _hoisted_1, [
    (_ctx.$subscription.hasEverHadSubscription)
      ? (_openBlock(), _createBlock(_component_PaymentInfoPanel, { key: 0 }))
      : _createCommentVNode("", true),
    _cache[3] || (_cache[3] = _createTextVNode()),
    _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "section-title" }, "\n      Manage your subscription\n    ", -1)),
    _cache[5] || (_cache[5] = _createTextVNode()),
    (_ctx.$subscription.hasEverHadSubscription)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("p", null, [
            _cache[0] || (_cache[0] = _createTextVNode("\n        Toggle the add-ons you would like enabled to edit your subscription.\n        If you have any questions regarding payments,\n        please consult our ")),
            _createElementVNode("a", {
              class: "link",
              href: _ctx.faqLink,
              target: "_blank"
            }, "FAQ", 8, _hoisted_2),
            _cache[1] || (_cache[1] = _createTextVNode(" or reach out via the Help button.\n      "))
          ]),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createVNode(_component_ManageAddonsPanel)
        ], 64))
      : (_openBlock(), _createBlock(_component_TrialPanel, { key: 2 }))
  ]))
}