import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-top" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "right flex-justified hidden-until-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumIcon = _resolveComponent("PremiumIcon")!
  const _component_BillingFrequencySelector = _resolveComponent("BillingFrequencySelector")!
  const _component_SubscriptionPriceBreakdown = _resolveComponent("SubscriptionPriceBreakdown")!
  const _component_BundleDiscountAmount = _resolveComponent("BundleDiscountAmount")!
  const _component_FeatureSummaries = _resolveComponent("FeatureSummaries")!
  const _component_TwoColumnModal = _resolveComponent("TwoColumnModal")!

  return (_openBlock(), _createBlock(_component_TwoColumnModal, {
    id: _ctx.id,
    class: "subscription-features-modal selector-modal themed light-theme reedsy-accented accent-premium full-height"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      _createVNode(_component_PremiumIcon, { size: "sm" })
    ]),
    left: _withCtx(() => [
      _createElementVNode("rbe-left-column", null, [
        _createElementVNode("rbe-billing-frequency", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "section-title" }, "\n            Billing frequency\n          ", -1)),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _createVNode(_component_BillingFrequencySelector, {
            modelValue: _ctx.interval,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.interval) = $event)),
            "disable-monthly": _ctx.hasActiveYearlySubscription,
            class: "billing-frequency-selector"
          }, null, 8, ["modelValue", "disable-monthly"])
        ]),
        _cache[5] || (_cache[5] = _createTextVNode()),
        _createVNode(_component_SubscriptionPriceBreakdown, {
          class: "price-breakdown",
          "calculated-price": _ctx.monthlyCalculatedPrice,
          loading: _ctx.isLoadingPrice,
          compact: "",
          "hide-details": _ctx.isMobile
        }, {
          default: _withCtx(() => [
            (!_ctx.isLoadingPrice)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["calculated-price", "loading", "hide-details"]),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createElementVNode("button", {
          ref: "continue",
          class: "button reedsy-accented continue-button",
          type: "button",
          disabled: !_ctx.calculatedPrice || _ctx.isLoadingPrice,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.continueToPayment && _ctx.continueToPayment(...args)))
        }, "\n          Continue\n        ", 8, _hoisted_3)
      ])
    ]),
    right: _withCtx(() => [
      _createElementVNode("rbe-header", _hoisted_4, [
        _cache[8] || (_cache[8] = _createElementVNode("p", null, "Select your add-ons", -1)),
        _cache[9] || (_cache[9] = _createTextVNode()),
        _createElementVNode("p", null, [
          _createVNode(_component_BundleDiscountAmount),
          _cache[7] || (_cache[7] = _createTextVNode(" with bundle discounts"))
        ])
      ]),
      _cache[10] || (_cache[10] = _createTextVNode()),
      _createVNode(_component_FeatureSummaries, {
        modelValue: _ctx.selectedProducts,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedProducts) = $event)),
        prices: _ctx.prices,
        interval: _ctx.interval
      }, null, 8, ["modelValue", "prices", "interval"])
    ]),
    _: 1
  }, 8, ["id"]))
}