
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {ICalculatePriceResponse, IDiscount} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import SubscriptionPrice from './subscription-price.vue';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {IBillingInterval} from '@reedsy/utils.subscription';
import TrialCountdownMixin from '@reedsy/studio.shared/mixins/subscriptions/trial-countdown';
import {isEmpty} from '@reedsy/utils.object';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {FEATURE_SUMMARIES} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';
import {IProductSummary} from '@reedsy/studio.shared/components/modals/components/subscription/features/product-summary.interface';

@Component({
  components: {
    SubscriptionPrice,
  },
})
export default class SubscriptionPriceBreakdown extends mixins(ClientSharedVue, TrialCountdownMixin) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @Prop({type: Object as PropType<ICalculatePriceResponse>, required: false})
  public calculatedPrice: ICalculatePriceResponse;

  @Prop({type: String})
  public nextBillingDate: string;

  @Prop({type: Boolean})
  public compact: boolean;

  @Prop({type: Boolean})
  public loading: boolean;

  @Prop({type: Boolean})
  public hideDetails: boolean;

  @Prop({type: Boolean})
  public hideNextBillingInfo: boolean;

  public get currency(): SupportedCurrency {
    return this.calculatedPrice?.currency;
  };

  public get interval(): IBillingInterval {
    return this.calculatedPrice?.interval;
  };

  public get isTrial(): boolean {
    return this.$subscription.isTrial;
  };

  public get vatAmount(): number {
    return this.calculatedPrice?.vatAmount || 0;
  }

  public get discounts(): IDiscount[] {
    if (!this.calculatedPrice) return [];

    const hasDiscounts = !isEmpty(this.calculatedPrice.discounts);
    return hasDiscounts ? this.calculatedPrice.discounts : [
      {title: 'Discounts', amount: 0},
    ];
  }

  public get products(): IProductSummary[] {
    return FEATURE_SUMMARIES.filter(({product}) => this.calculatedPrice.products[product]);
  }

  public get discounted(): boolean {
    if (!this.calculatedPrice) return false;
    return this.calculatedPrice.total !== this.calculatedPrice.subtotal;
  }

  public get total(): number {
    return this.calculatedPrice?.total;
  }
}
