export enum DiscountType {
  Annual = 'annual',
  Bundle = 'bundle',
}

const PERCENTAGE_DISCOUNT: Record<DiscountType, number> = {
  [DiscountType.Annual]: 10,
  [DiscountType.Bundle]: 10,
};

export function getDiscountPercentage(discountType: DiscountType): number {
  return PERCENTAGE_DISCOUNT[discountType];
}
