import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "enabled-title"
}
const _hoisted_2 = { class: "flex-center" }
const _hoisted_3 = {
  key: 0,
  class: "thin"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "trial-price-info"
}
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubscriptionProductPrice = _resolveComponent("SubscriptionProductPrice")!
  const _component_FreeFeatureBadge = _resolveComponent("FreeFeatureBadge")!
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!
  const _component_FeatureToggle = _resolveComponent("FeatureToggle")!
  const _component_VuiIconTick = _resolveComponent("VuiIconTick")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isProduct ? 'button' : 'div'), {
    class: _normalizeClass(["feature-summary reedsy-accented", {
      product: _ctx.isProduct,
      'vui-tab-option': _ctx.isTabOption, // Reuse some styling but without the full component
      active: _ctx.isProduct && _ctx.isActive,
      'being-removed': _ctx.showEnabledInfo && _ctx.isRemovingAddon,
      collapsable: _ctx.collapsable,
      'preview': _ctx.isPreview
    }]),
    onClick: _ctx.toggleSelected
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", {
        class: "flex-justified",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
      }, [
        _createElementVNode("div", null, [
          (_ctx.showEnabledInfo)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.enabledInfo), 1))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.summary.title), 1)
        ]),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createElementVNode("rbe-title-actions", _hoisted_2, [
          (_ctx.isProduct && !_ctx.isPreview)
            ? (_openBlock(), _createBlock(_component_SubscriptionProductPrice, {
                key: 0,
                prices: _ctx.prices,
                product: _ctx.summary.product,
                class: _normalizeClass([{
            'highlighted': _ctx.selected && !_ctx.collapsable,
          }, "product-price"])
              }, null, 8, ["prices", "product", "class"]))
            : (!_ctx.isPreview)
              ? (_openBlock(), _createBlock(_component_FreeFeatureBadge, { key: 1 }))
              : _createCommentVNode("", true),
          _cache[2] || (_cache[2] = _createTextVNode()),
          (_ctx.collapsable)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.expanded ? 'VuiIconUp' : 'VuiIconDown'), { key: 2 }))
            : _createCommentVNode("", true)
        ])
      ]),
      _cache[4] || (_cache[4] = _createTextVNode()),
      (!_ctx.collapsable)
        ? (_openBlock(), _createElementBlock("hr", _hoisted_3))
        : _createCommentVNode("", true),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _createVNode(_component_ExpandTransition, null, {
        default: _withCtx(() => [
          (!_ctx.collapsable || _ctx.expanded)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.features, (feature) => {
                  return (_openBlock(), _createElementBlock("li", { key: feature }, _toDisplayString(feature), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _cache[6] || (_cache[6] = _createTextVNode()),
      (_ctx.isPreview && _ctx.prices)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createTextVNode("\n      Free for " + _toDisplayString(_ctx.config.stripe.trialDays) + " days, then\n      ", 1),
            _createVNode(_component_SubscriptionProductPrice, {
              class: "price",
              prices: _ctx.prices,
              product: _ctx.summary.product
            }, null, 8, ["prices", "product"])
          ]))
        : (_ctx.isProduct)
          ? (_openBlock(), _createBlock(_component_FeatureToggle, {
              key: 2,
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
              class: "feature-toggle"
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true),
      _cache[7] || (_cache[7] = _createTextVNode()),
      (_ctx.showEnabledInfo && _ctx.isRemovingAddon)
        ? (_openBlock(), _createElementBlock("rbe-removal-info", _hoisted_6, [
            _createElementVNode("p", null, "\n        This add-on was removed from your subscription and\n        will be disabled on " + _toDisplayString(_ctx.cancellationDate) + ".\n      ", 1)
          ]))
        : _createCommentVNode("", true),
      _cache[8] || (_cache[8] = _createTextVNode()),
      (_ctx.isTabOption)
        ? (_openBlock(), _createBlock(_component_VuiIconTick, {
            key: 4,
            class: "icon-tick"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "onClick"]))
}