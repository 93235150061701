import IShareDb from '@reedsy/studio.shared/services/sharedb/i-sharedb';
import {Logger} from '@reedsy/reedsy-logger-js';
import ShareDbError from '@reedsy/studio.shared/errors/sharedb-error';
import {Source} from '@reedsy/studio.isomorphic/models/source';
import {Connection, Doc, Presence, Query, Snapshot} from 'sharedb/lib/client';
import {Collection, CollectionMutation, ICollectionOpMap, ICollectionPresenceMap, ICollectionTypeMap} from '@reedsy/reedsy-sharedb/lib/common/collection-types';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {isValidId} from '@reedsy/reedsy-sharedb/lib/common/is-valid-id';
import {injectable} from 'inversify';
import IShareDbConnection from './i-sharedb-connection';
import {ErrorCode} from '@reedsy/reedsy-sharedb/lib/errors/error-code';
import {SubscriptionRequiredError} from '@reedsy/studio.shared/errors/subscription-required-error';
import {wrapDoc} from '@reedsy/reedsy-sharedb/lib/common/wrapped-doc';
import {DocInHardRollbackError} from '@reedsy/studio.shared/errors/doc-in-hard-rollback';
import {dig} from '@reedsy/utils.dig';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {IDocPaywallInfoMap} from './doc-paywall-info';

@injectable()
export default abstract class ShareDb<
  T extends Collection,
  TShareDBConnection extends IShareDbConnection<any> = IShareDbConnection<any>,
> implements IShareDb<T> {
  protected abstract handleUnauthorized(error: any, data: any): Promise<void>;

  @$lazyInject('DocPaywallInfoMap')
  public readonly docPaywallInfo: IDocPaywallInfoMap;

  public readonly logger: Logger;
  public readonly shareDbConnection: TShareDBConnection;

  public constructor(
    loggerFactory: LoggerFactory,
    shareDbConnection: TShareDBConnection,
  ) {
    this.logger = loggerFactory.create('ShareDbService');
    this.shareDbConnection = shareDbConnection;
  }

  public get connection(): Connection {
    return this.shareDbConnection.connection;
  }

  public get<C extends Collection>(collection: C, id: string): Doc<ICollectionTypeMap[C]> {
    if (!isValidId(collection, id)) {
      this.logger.error(new Error('Invalid ID'), {data: {collection, id}});
      return null;
    }

    const doc = this.connection.get(collection, id) as Doc<ICollectionTypeMap[C]>;
    doc.submitSource = true;
    if (!dig(this.docPaywallInfo.get(doc), 'hasPayWallHandlersSet')) {
      this.docPaywallInfo.set(doc, {
        isPayWalled: false,
        hasPayWallHandlersSet: true,
      });

      doc.on('load', () => {
        this.docPaywallInfo.get(doc).isPayWalled = false;
      });
      doc.on('destroy', () => {
        this.docPaywallInfo.delete(doc);
      });
    }

    return doc;
  }

  public subscribe<C extends Collection>(collection: C, id: string): Promise<Doc<ICollectionTypeMap[C]>> {
    return new Promise((resolve, reject) => {
      const doc = this.get(collection, id);
      doc.subscribe(async (error) => {
        const wrappedError = await this.getWrappedErrorIfAny(error, doc, {id, collection});
        this.handlePromise(wrappedError, () => resolve(doc), reject);
      });
    });
  }

  public fetchSnapshot<C extends Collection>(
    collection: C,
    id: string,
    timestamp: number,
  ): Promise<Snapshot<ICollectionTypeMap[C]>> {
    return new Promise((resolve, reject) => {
      this.connection.fetchSnapshotByTimestamp(collection, id, timestamp, async (error, snapshot) => {
        const wrappedError = await this.getWrappedErrorIfAny(error, null, {id, collection, timestamp});
        this.handlePromise(wrappedError, () => resolve(snapshot), reject);
      });
    });
  }

  public submitOp<C extends Collection>(
    collection: C,
    id: string,
    op: ICollectionOpMap[C][],
    source: Source,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const doc = this.get(collection, id);
      const options: any = {source};
      doc.submitOp(op, options, async (error) => {
        const wrappedError = await this.getWrappedErrorIfAny(error, doc, {id, collection, op, data: doc.data});
        this.handlePromise(wrappedError, resolve, reject);
      });
    });
  }

  public async mutate<C extends Collection>(
    collection: C,
    id: string,
    mutation: CollectionMutation<C>,
    source: Source,
  ): Promise<void> {
    const doc = this.get(collection, id);
    const wrappedDoc = wrapDoc(doc);
    try {
      await wrappedDoc.mutate({source}, mutation);
    } catch (error) {
      const wrappedError = await this.getWrappedErrorIfAny(error, doc, {id, collection});
      if (wrappedError) throw wrappedError;
    }
  }

  public getPresence<
    C extends string,
    T = C extends keyof ICollectionPresenceMap ? ICollectionPresenceMap[C] : any,
  >(collection: C, id?: string): Presence<T> {
    if (!id) return this.connection.getPresence(collection);
    return this.connection.getDocPresence(collection, id);
  }

  public subscribePresence<C extends keyof ICollectionPresenceMap>(
    collection: C,
    id: string,
  ): Promise<Presence<ICollectionPresenceMap[C]>> {
    return new Promise((resolve, reject) => {
      const presence = this.getPresence(collection, id);
      presence.subscribe(async (error) => {
        const wrappedError = await this.getWrappedErrorIfAny(error, null, {id, collection});
        this.handlePromise(wrappedError, () => resolve(presence), reject);
      });
    });
  }

  public subscribeQuery<C extends Collection>(collection: C, query: any = {}): Promise<Query<ICollectionTypeMap[C]>> {
    return new Promise((resolve, reject) => {
      const subscribeQuery = this.connection.createSubscribeQuery(collection, query, null, (error) => {
        if (error) return reject(error);
        resolve(subscribeQuery);
      });
    });
  }

  public fetchQuery<C extends Collection>(collection: C, query: any): Promise<Query<ICollectionTypeMap[C]>> {
    return new Promise((resolve, reject) => {
      const fetchQuery = this.connection.createFetchQuery(collection, query, null, (error) => {
        if (error) return reject(error);
        resolve(fetchQuery);
      });
    });
  }

  public destroy<C extends Collection>(collection: C, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const doc = this.get(collection, id);
      doc.destroy(async (error) => {
        const wrappedError = await this.getWrappedErrorIfAny(error, doc, {id, collection});
        this.handlePromise(wrappedError, resolve, reject);
      });
    });
  }

  public del<C extends Collection>(collection: C, id: string, source: Source): Promise<void> {
    return new Promise((resolve, reject) => {
      const doc = this.get(collection, id);
      doc.del({source}, async (error) => {
        if (error) return reject(error);
        await this.destroy(collection, id);
        resolve();
      });
    });
  }

  private handlePromise(wrappedError: Error, resolve: () => void, reject: (error: any) => void): void {
    if (wrappedError) reject(wrappedError);
    else resolve();
  }

  private async getWrappedErrorIfAny(error: any, doc: Doc, data: any): Promise<Error> {
    if (!error) return null;

    switch (error.code) {
      case ErrorCode.Unauthorized:
        await this.handleUnauthorized(error, data);
      case ErrorCode.SubscriptionRequired:
        return this.handleSubscriptionRequiredError(error, doc);
      case 'ERR_DOC_IN_HARD_ROLLBACK':
        return this.handleHardRollbackError(doc);
      default:
        error = new ShareDbError(error);
        this.logger.error(error, {data});
        return error;
    }
  }

  private handleSubscriptionRequiredError(error: any, doc: Doc<any>): Error {
    if (this.docPaywallInfo.has(doc)) {
      this.docPaywallInfo.get(doc).isPayWalled = true;
    }
    return new SubscriptionRequiredError(error);
  }

  private handleHardRollbackError(doc: Doc<any>): Error {
    if (!dig(this.docPaywallInfo.get(doc), 'isPayWalled')) return new DocInHardRollbackError();

    this.logger.debug(
      'Ignoring ERR_DOC_IN_HARD_ROLLBACK error for op submit. The collection is behind the paywall',
    );
  }
}
