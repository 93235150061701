import {getAppliedStyles} from '@reedsy/studio.shared/utils/html/get-applied-styles';
import {STRIPE_INPUT_CSS_PROPERTIES, STRIPE_LABEL_CSS_PROPERTIES} from './stripe-css-properties';
import {merge, pick} from '@reedsy/utils.object';
import {Stripe, StripeElements, StripeElementsOptions} from '@stripe/stripe-js';

const FORCE_THEME = 'themed light-theme';

export function getStripeElements(stripe: Stripe, options: StripeElementsOptions): StripeElements {
  const appearance = merge({
    theme: 'stripe',
    rules: {
      '.Label': pick(
        getAppliedStyles('label', {class: `font-size-sm padding-bottom-1em ${FORCE_THEME}`}),
        ...STRIPE_LABEL_CSS_PROPERTIES,
      ),
      '.Input': pick(
        getAppliedStyles('input', {type: 'text', class: FORCE_THEME}),
        ...STRIPE_INPUT_CSS_PROPERTIES,
      ),
      '.Input:focus': pick(
        getAppliedStyles('input', {type: 'text', class: FORCE_THEME}, {getFocusStylesByStealingFocus: true}),
        ...STRIPE_INPUT_CSS_PROPERTIES,
      ),
      '.Input--invalid': pick(
        getAppliedStyles('input', {type: 'text', class: `invalid ${FORCE_THEME}`}),
        ...STRIPE_INPUT_CSS_PROPERTIES,
      ),
      '.Input--invalid:focus': pick(
        getAppliedStyles('input', {type: 'text', class: `invalid focused ${FORCE_THEME}`}),
        ...STRIPE_INPUT_CSS_PROPERTIES,
      ),
      '.AccordionItem--selected': pick(
        getAppliedStyles('p', {class: `text-accent reedsy-accented accent-premium ${FORCE_THEME}`}),
        'color',
      ),
      '.Error': pick(
        getAppliedStyles('p', {class: `xs text-accent reedsy-accented danger padding-top-1em ${FORCE_THEME}`}),
        ...STRIPE_LABEL_CSS_PROPERTIES,
      ),
    },
  }, options.appearance);

  return stripe.elements({
    ...options,
    appearance,
  } as any);
}
