<template>
  <VuiAccountDropdown
    class="user-settings-menu"
    :class="{'has-paid-features': hasAnyPaidFeature}"
    :options="options"
    :user-firstname="user.firstName"
    :user-lastname="user.lastName"
    :user-avatar="user.avatarUrl"
    :style="{'--vui-accountDropdown-initials-bgColor': user.color}"
  >
    <template
      v-if="hasAnyPaidFeature"
      #avatar-badge
    >
      <PremiumIcon size="xs" />
    </template>
  </VuiAccountDropdown>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';

interface IOption {
  id: string;
  text: string;
  onClick?: () => Promise<void>;
  url?: string;
}

@Component({
  components: {
    Avatar,
    PremiumIcon,
  },
})
export default class UserSettingsMenu extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @$lazyInjectStore(StoreName.Collaborators)
  public $collaborators: BookshelfCollaboratorsModule;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public readonly $subscription: SharedSubscriptionModule;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInject('Navigation')
  public readonly _navigation: INavigation;

  public readonly options: IOption[] = [
    {id: 'account-settings', text: 'Account settings', url: this.accountSettingsUrl},
    {id: 'help', text: 'Help', onClick: this.openHelpModal},
    {id: 'log-out', text: 'Log out', onClick: this.logOut},
  ];

  public get accountSettingsUrl(): string {
    return this._navigation.editorAccountSettingsUrl;
  }

  public get user(): IUserInfo {
    return this.$user.info;
  }

  public get hasAnyPaidFeature(): boolean {
    return this.$subscription.hasAnyPaidFeature;
  }

  public async logOut(): Promise<void> {
    await this.$collaborators.logout();
  }

  public async openHelpModal(): Promise<void> {
    this.$modals.open('HelpModal');
  }
}
</script>

<style lang="scss" scoped>
.user-settings-menu {
  &.has-paid-features {
    --local-account-color-default: var(--reedsy-accent-premium);
    --local-glow: #{$box-shadow-premium-glow};
  }

  :deep(.options) {
    z-index: $z-index-top-bar-dropdown;
  }
}
</style>
