<template>
  <rbe-period-toggle-wrapper class="flex-center">
    <rbe-period-toggle class="flex-justified">
      <button
        class="reedsy-accented"
        type="button"
        @click="annual = false"
      >
        Billed monthly
      </button>

      <VuiToggle v-model="annual" />

      <button
        class="reedsy-accented"
        type="button"
        @click="annual = true"
      >
        Annually

        <DiscountAmount discount-type="bundle" />
      </button>
    </rbe-period-toggle>
  </rbe-period-toggle-wrapper>
</template>

<script lang="ts">
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import DiscountAmount from '@reedsy/studio.shared/components/modals/components/subscription/discount-amount.vue';

@Component({
  components: {
    DiscountAmount,
  },
})
export default class PeriodToggle extends ClientSharedVue {
  @Model
  public annual: boolean;
}
</script>

<style lang="scss" scoped>
rbe-period-toggle-wrapper {
  flex-wrap: wrap;
}

rbe-period-toggle-wrapper,
rbe-period-toggle {
  gap: $space-sm;
}

rbe-period-toggle button {
  @include font-family($controls);

  display: flex;
  gap: $space-xs;
}
</style>
