<template>
  <rbe-subscription-account-settings
    class="reedsy-accented accent-premium flex-top"
  >
    <PaymentInfoPanel v-if="$subscription.hasEverHadSubscription" />
    <h1 class="section-title">
      Manage your subscription
    </h1>
    <template v-if="$subscription.hasEverHadSubscription">
      <p>
        Toggle the add-ons you would like enabled to edit your subscription.
        If you have any questions regarding payments,
        please consult our <a
          class="link"
          :href="faqLink"
          target="_blank"
        >FAQ</a> or reach out via the Help button.
      </p>
      <ManageAddonsPanel />
    </template>
    <TrialPanel v-else />
  </rbe-subscription-account-settings>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import Navigation from '@reedsy/studio.shared/services/navigation';
import TrialPanel from '@reedsy/studio.home.bookshelf/components/subscription-settings/trial-panel.vue';
import PaymentInfoPanel from '@reedsy/studio.home.bookshelf/components/subscription-settings/payment-info-panel.vue';
import ManageAddonsPanel from '@reedsy/studio.home.bookshelf/components/subscription-settings/manage-addons-panel.vue';
import {config} from '@reedsy/studio.shared/config';

@Component({
  components: {
    TrialPanel,
    PaymentInfoPanel,
    ManageAddonsPanel,
  },
})
export default class SubscriptionAccountSettings extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInject('Navigation')
  public $navigation: Navigation;

  public get billingPortalUrl(): string {
    return this.$navigation.billingPortalUrl;
  }

  public get faqLink(): string {
    return config.reedsyApps.faq.url;
  }

  public openPremiumModal(): void {
    this.$subscriptionModal.open();
  }
}
</script>

<style lang="scss" scoped>
rbe-subscription-account-settings {
  gap: $space-md;

  .section-title {
    font-size: $font-size-lg;
    margin-top: $space-base;

    @include font-family($controls, bold);
  }
}
</style>
