
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IPaymentMethodInfo} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-payment-info';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import Navigation from '@reedsy/studio.shared/services/navigation';

const CARD_BRAND_NAME_MAPPING: Record<string, string> = Object.freeze({
  american_express: 'American Express',
  cartes_bancaires: 'Cartes Bancaires',
  diners_club: 'Diners Club',
  discover: 'Discover',
  eftpos_australia: 'eftpos Australia',
  interac: 'Interac',
  jcb: 'JCB',
  mastercard: 'MasterCard',
  union_pay: 'Union Pay',
  visa: 'Visa',
});

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class PaymentMethodInfo extends ClientSharedVue {
  @$lazyInject('Api')
  public $api: IApi;

  @$lazyInject('Navigation')
  public $navigation: Navigation;

  public paymentMethodInfo: IPaymentMethodInfo = null;

  public get brand(): string {
    const brandCode = this.paymentMethodInfo.cardInfo.brand;
    const cardBrandName = CARD_BRAND_NAME_MAPPING[brandCode];
    return cardBrandName || brandCode;
  }

  public get lastFourDigits(): string {
    return this.paymentMethodInfo.cardInfo.last4digits;
  }

  public get billingPortalUrl(): string {
    return this.$navigation.billingPortalUrl;
  }

  public async mounted(): Promise<void> {
    this.paymentMethodInfo = await this.$api.fetchSubscriptionPaymentMethodInfo();
  }
}
