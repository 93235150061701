<template>
  <InfoTip v-bind="$attrs">
    <template #trigger>
      <slot name="trigger">
        <PremiumBadgeInfo />
      </slot>
    </template>

    <template #default="{hide}">
      <rbe-premium-info>
        <PremiumBadge />
        <rbe-premium-copy>
          <slot />
        </rbe-premium-copy>
        <button
          v-if="!$subscription.hasFeature(paidFeature)"
          ref="freeTrialButton"
          class="free-trial-button"
          @click="$subscriptionModal.open(); hide()"
        >
          Start your free trial &rarr;
        </button>
      </rbe-premium-info>
    </template>
  </InfoTip>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import InfoTip from '@reedsy/studio.shared/components/info-tip/info-tip.vue';
import PremiumBadge from '@reedsy/studio.shared/components/subscriptions/premium-badge.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import PremiumBadgeInfo from '@reedsy/studio.shared/components/subscriptions/premium-badge-info.vue';
import {PropType} from 'vue';
import {IPaidFeature} from '@reedsy/utils.subscription';

@Component({
  components: {
    PremiumBadgeInfo,
    PremiumBadge,
    InfoTip,
  },
})
export default class PremiumInfo extends ClientSharedVue {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @Prop({type: String as PropType<IPaidFeature>, required: true})
  public paidFeature: IPaidFeature;
}
</script>

<style lang="scss" scoped>
.info-tip {
  $tooltip-offset: $space-sm;

  padding: $tooltip-offset 0;
  margin: -$tooltip-offset 0;
}

rbe-premium-info {
  display: grid;
  gap: $space-sm;
  justify-items: left;
}

button.free-trial-button {
  color: var(--reedsy-accent-premium-emphasis);
}
</style>
