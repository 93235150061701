
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import capitalize from 'capitalize';

@Component({
  components: {
    PlainModal,
  },
})
export default class SubscriptionPaymentSuccess extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  public readonly cancelable = true;

  public get userName(): string {
    return capitalize(this.$user.info.firstName || '');
  }
}
