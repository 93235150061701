
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import {config} from '@reedsy/studio.shared/config';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {IBillingInterval} from '@reedsy/utils.subscription';
import SubscriptionProductPrice from '@reedsy/studio.shared/components/subscriptions/subscription-product-price.vue';
import FreeFeatureBadge from './free-feature-badge.vue';
import FeatureToggle from './feature-toggle.vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';

@Component({components: {
  SubscriptionProductPrice,
  FreeFeatureBadge,
  FeatureToggle,
  ExpandTransition,
}})
export default class FeatureSummary extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Boolean, default: false})
  public isPreview: boolean;

  @Prop({type: Boolean, default: false})
  public showEnabledInfo: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  @Prop({type: Boolean, default: false})
  public collapsable: boolean;

  public readonly config = config;
  public expanded = true;

  public get isProduct(): boolean {
    return !!this.summary.product;
  }

  public get isTabOption(): boolean {
    return this.isProduct && !this.collapsable;
  }

  public get isActive(): boolean {
    return this.isPreview || this.selected;
  }

  public get currentSubscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get currentPeriodProducts(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.products);
  }

  public get nextPeriodProducts(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.nextBilling.products);
  }

  public get isRemovingAddon(): boolean {
    return this.currentPeriodProducts.has(this.summary.product) &&
    !this.nextPeriodProducts.has(this.summary.product);
  }

  public get cancellationDate(): string {
    return this.currentSubscription.nextBilling.date;
  }

  public get enabledInfo(): string {
    if (!this.isProduct) return 'Included';

    let enabledInfo = 'Enabled';

    if (this.isRemovingAddon) {
      enabledInfo += ` until ${this.cancellationDate}`;
    }

    return enabledInfo;
  }

  public toggleSelected(): void {
    if (!this.isProduct || this.collapsable) return;
    this.selected = !this.selected;
  }
}
