import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "price-confirmation" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex-center discount-title" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "price-headline"
}
const _hoisted_7 = {
  ref: "vat-info",
  class: "vat-info"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubscriptionPrice = _resolveComponent("SubscriptionPrice")!
  const _component_VuiIconTick = _resolveComponent("VuiIconTick")!

  return (_openBlock(), _createElementBlock("rbe-price-info", {
    class: _normalizeClass(["flex-top", {compact: _ctx.compact}])
  }, [
    (!_ctx.hideDetails && _ctx.calculatedPrice)
      ? (_openBlock(), _createElementBlock("rbe-price-info-section", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "header" }, "\n        Add-ons\n      ", -1)),
          _cache[2] || (_cache[2] = _createTextVNode()),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (selectedProduct) => {
            return (_openBlock(), _createElementBlock("rbe-price-item", {
              key: selectedProduct.product,
              class: "product-price"
            }, [
              _createElementVNode("span", null, _toDisplayString(selectedProduct.title), 1),
              _cache[0] || (_cache[0] = _createTextVNode()),
              _createVNode(_component_SubscriptionPrice, {
                price: _ctx.calculatedPrice.products[selectedProduct.product].amount,
                currency: _ctx.currency
              }, null, 8, ["price", "currency"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _cache[17] || (_cache[17] = _createTextVNode()),
    _createElementVNode("rbe-price-info-section", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _cache[14] || (_cache[14] = _createTextVNode()),
      (!_ctx.hideDetails)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.calculatedPrice)
              ? (_openBlock(), _createElementBlock("rbe-price-item", _hoisted_3, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "header" }, "Subtotal", -1)),
                  _cache[4] || (_cache[4] = _createTextVNode()),
                  _createVNode(_component_SubscriptionPrice, {
                    price: _ctx.calculatedPrice.subtotal,
                    currency: _ctx.currency
                  }, null, 8, ["price", "currency"])
                ]))
              : _createCommentVNode("", true),
            _cache[6] || (_cache[6] = _createTextVNode()),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.discounts, (discount) => {
              return (_openBlock(), _createElementBlock("rbe-price-item", {
                key: discount.title
              }, [
                _createElementVNode("span", _hoisted_4, [
                  _createTextVNode(_toDisplayString(discount.title) + " ", 1),
                  (discount.amount)
                    ? (_openBlock(), _createBlock(_component_VuiIconTick, {
                        key: 0,
                        class: "reedsy-accented"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _cache[5] || (_cache[5] = _createTextVNode()),
                _createVNode(_component_SubscriptionPrice, {
                  class: "discount-price",
                  price: -discount.amount,
                  currency: _ctx.currency
                }, null, 8, ["price", "currency"])
              ]))
            }), 128)),
            _cache[7] || (_cache[7] = _createTextVNode()),
            (_ctx.loading || _ctx.calculatedPrice)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_5))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _cache[15] || (_cache[15] = _createTextVNode()),
      _createElementVNode("div", null, [
        (_ctx.loading || _ctx.calculatedPrice)
          ? (_openBlock(), _createElementBlock("rbe-price-item", _hoisted_6, [
              _createElementVNode("span", null, _toDisplayString(_ctx.compact ? 'Total' : 'Your subscription'), 1),
              _cache[9] || (_cache[9] = _createTextVNode()),
              _createElementVNode("div", null, [
                (_ctx.discounted)
                  ? (_openBlock(), _createBlock(_component_SubscriptionPrice, {
                      key: 0,
                      class: "before-discount",
                      price: _ctx.calculatedPrice.subtotal,
                      currency: _ctx.currency
                    }, null, 8, ["price", "currency"]))
                  : _createCommentVNode("", true),
                _cache[8] || (_cache[8] = _createTextVNode()),
                _createVNode(_component_SubscriptionPrice, {
                  class: "highlighted",
                  price: _ctx.total,
                  currency: _ctx.currency,
                  interval: _ctx.interval
                }, null, 8, ["price", "currency", "interval"])
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[12] || (_cache[12] = _createTextVNode()),
        _createElementVNode("p", _hoisted_7, [
          (_ctx.vatAmount > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[10] || (_cache[10] = _createTextVNode("\n            The applicable VAT amount of\n            ")),
                _createVNode(_component_SubscriptionPrice, {
                  price: _ctx.vatAmount,
                  currency: _ctx.currency,
                  interval: _ctx.interval
                }, null, 8, ["price", "currency", "interval"]),
                _cache[11] || (_cache[11] = _createTextVNode("\n            is included in the total price.\n          "))
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode("\n            There’s no VAT charged on this subscription.\n          ")
              ], 64))
        ], 512)
      ]),
      _cache[16] || (_cache[16] = _createTextVNode()),
      (_ctx.nextBillingDate)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
            (_ctx.isTrial && _ctx.trialDaysLeft)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "You have " + _toDisplayString(_ctx.trialDaysLeft) + " days remaining of your free trial.", 1))
              : _createCommentVNode("", true),
            _cache[13] || (_cache[13] = _createTextVNode()),
            (!_ctx.hideNextBillingInfo)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Your first billing date will be " + _toDisplayString(_ctx.nextBillingDate), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}