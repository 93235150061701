import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex-top" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createElementBlock("rbe-payment-info", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "payment-type" }, "\n      Payment Type\n    ", -1)),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (_ctx.paymentMethodInfo)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          ref: "card-info",
          class: "card-info"
        }, _toDisplayString(_ctx.brand) + " •••• " + _toDisplayString(_ctx.lastFourDigits), 513))
      : (_openBlock(), _createBlock(_component_SkeletonLoader, { key: 1 })),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createElementVNode("a", {
      ref: "payment-settings-link",
      href: _ctx.billingPortalUrl,
      target: "_blank",
      class: "link reversed"
    }, "Manage Payments", 8, _hoisted_2)
  ]))
}