import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconTick = _resolveComponent("VuiIconTick")!

  return (_openBlock(), _createElementBlock("rbe-period-summary", {
    class: _normalizeClass(["reedsy-accented product vui-tab-option", {active: _ctx.selected}])
  }, [
    _createElementVNode("div", null, _toDisplayString(_ctx.periodTitle), 1),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createElementVNode("span", {
      class: _normalizeClass(["reedsy-accented", {'neutral-highContrast font-weight-normal': _ctx.interval === 'month'}])
    }, _toDisplayString(_ctx.discount), 3),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createVNode(_component_VuiIconTick, { class: "icon-tick" })
  ], 2))
}