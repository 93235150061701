
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {IBillingInterval} from '@reedsy/utils.subscription';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {currencyAmount} from '@reedsy/studio.shared/filters/currency-amount';

const INTERVAL_SEPARATOR: string = '/';
const INTERVAL_DISPLAY: Record<IBillingInterval, string> = {
  month: 'mo',
  year: 'yr',
};

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class SubscriptionPrice extends ClientSharedVue {
  @Prop({type: Number})
  public price: number;

  @Prop({type: String as PropType<SupportedCurrency>})
  public currency: SupportedCurrency;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  public get loading(): boolean {
    return !(this.price !== undefined && this.currency);
  }

  public get amount(): string {
    return currencyAmount(this.price, this.currency);
  }

  public get intervalDisplay(): string {
    return INTERVAL_SEPARATOR + INTERVAL_DISPLAY[this.interval];
  }
}
