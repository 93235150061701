import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-centered" }
const _hoisted_2 = { class: "flex-centered" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconCrown = _resolveComponent("VuiIconCrown")!
  const _component_PlainModal = _resolveComponent("PlainModal")!

  return (_openBlock(), _createBlock(_component_PlainModal, {
    id: _ctx.id,
    class: "accent-premium transparent gradient-overlay",
    headless: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-welcome-section", _hoisted_1, [
        _createElementVNode("rbe-premium-icon", null, [
          _cache[1] || (_cache[1] = _createElementVNode("rbe-premium-icon", { class: "ping" }, null, -1)),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createVNode(_component_VuiIconCrown)
        ]),
        _cache[8] || (_cache[8] = _createTextVNode()),
        _createElementVNode("rbe-welcome-text", _hoisted_2, [
          _createElementVNode("h1", null, [
            _cache[3] || (_cache[3] = _createTextVNode("\n          Welcome to the club")),
            (_ctx.userName)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, ", " + _toDisplayString(_ctx.userName), 1))
              : _createCommentVNode("", true)
          ]),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "\n          Your add-ons have been unlocked.\n        ", -1)),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _cache[7] || (_cache[7] = _createElementVNode("p", null, "\n          Thank you for subscribing and happy writing!\n        ", -1))
        ]),
        _cache[9] || (_cache[9] = _createTextVNode()),
        _createElementVNode("button", {
          class: "button reedsy-accented",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, "\n        Continue\n      ")
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}