
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import AddOnToggle from './add-on-toggle.vue';
import {config} from '@reedsy/studio.shared/config';
import SubscriptionStartedBase from './subscription-started-base.vue';
import {FEATURE_SUMMARIES} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';
import {IProductSummary} from '@reedsy/studio.shared/components/modals/components/subscription/features/product-summary.interface';

@Component({
  components: {
    AddOnToggle,
    SubscriptionStartedBase,
  },
})
export default class SubscriptionTrialStarted extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({required: false, default: FEATURE_SUMMARIES})
  public featureSummaries: IProductSummary[];

  public readonly config = config;
  public readonly cancelable = true;

  public get allAddonsTitles(): string[] {
    return this.featureSummaries
      .filter((feature) => !!feature.product)
      .map((feature) => feature.title);
  }
}
