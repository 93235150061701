import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {ISettings} from '@reedsy/reedsy-sharedb/lib/common/user-settings/settings';
import {memoize} from '@reedsy/utils.decorator';
import {clone} from '@reedsy/utils.clone';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {OpSource} from '@reedsy/studio.isomorphic/models/op-source';
import {IShareDbModule} from '@reedsy/studio.shared/store/modules/sharedb/i-share-db-module';
import {Store} from 'vuex';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {NotificationMedium} from '@reedsy/reedsy-sharedb/lib/common/user-settings/notification-medium';
import {IApplyOpsMutation, IDataMutation, subscribeVuexToDoc} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';
import {WritingStage} from '@reedsy/reedsy-sharedb/lib/common/user-settings/writing-stage';
import {CollectionMutation} from '@reedsy/reedsy-sharedb/lib/common/collection-types';
import {isOsDarkMode} from '@reedsy/studio.shared/utils/is-os-dark-mode';
import {AppTheme} from './app-theme';
import {AppThemeOption} from '@reedsy/reedsy-sharedb/lib/common/user-settings/app-theme-option';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';

// eslint-disable-next-line @stylistic/max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function userSettingsModuleFactory(
  name: string,
  store: Store<any>,
  $shareDB: IShareDbModule<'userSettings' | 'feedbackResponses'>,
  $user: SharedUserModule,
  $sharedSubscriptionModule: SharedSubscriptionModule,
) {
  @Module({name, store})
  class BaseUserSettingsModule extends VuexModule {
    public settings: ISettings = null;

    public get loaded(): boolean {
      return !!this.settings;
    }

    public get isAfterSignUpSurveySkipped(): boolean {
      return this.settings.writingStage === null;
    }

    public get isAfterSignUpSurveyCompleted(): boolean {
      return !!this.settings.writingStage;
    }

    public get appTheme(): AppTheme {
      return this.rootThemeClass || this.operatingSystemAppTheme;
    }

    public get rootThemeClass(): AppTheme | null {
      if (!$sharedSubscriptionModule.hasFeature('darkTheme')) return AppTheme.Light;

      if (!this.settings) {
        return null;
      }

      switch (this.settings.appTheme) {
        case AppThemeOption.Light:
          return AppTheme.Light;

        case AppThemeOption.Dark:
          return AppTheme.Dark;

        case AppThemeOption.System:
        default:
          return null;
      }
    }

    private get operatingSystemAppTheme(): AppTheme {
      return isOsDarkMode.value ? AppTheme.Dark : AppTheme.Light;
    }

    @Mutation
    public DATA({data}: IDataMutation<'userSettings'>): void {
      this.settings = clone(data.settings);
    }

    @Mutation
    public APPLY_OPS({ops}: IApplyOpsMutation<'userSettings'>): void {
      ops.forEach((op) => applyOp(this, op));
    }

    @Action
    @memoize
    public async initialise(): Promise<void> {
      await $user.initialise();
      const doc = await $shareDB.subscribe({
        collection: 'userSettings',
        id: $user.id,
      });
      subscribeVuexToDoc(doc, this);
    }

    @Action
    public async setBoardsBetaModalSeen(): Promise<void> {
      await this.mutate((data) => {
        data.settings.boardsBetaModalSeen = true;
      });
    }

    @Action
    public async setManuscriptTutorialSeen(): Promise<void> {
      await this.mutate((data) => data.settings.manuscriptTutorialSeen = new Date().toISOString());
    }

    @Action
    public async toggleNotificationMedium({medium, enable}: {
      medium: NotificationMedium;
      enable: boolean;
    }): Promise<void> {
      await this.mutate((data) => {
        data.settings.notifications.disabledMediums[medium] = !enable;
      });
    }

    @Action
    public async setCheckInNotificationTime(time: string): Promise<void> {
      await this.mutate((data) => {
        data.settings.notifications.checkIn = time;
      });
    }

    @Action
    public async setWritingStage(writingStage: WritingStage): Promise<void> {
      await this.mutate((data) => {
        data.settings.writingStage = writingStage;
      });
    }

    @Action
    public async setAppThemeOption(appThemeOption: AppThemeOption): Promise<void> {
      await this.mutate((data) => {
        data.settings.appTheme = appThemeOption;
      });
    }

    @Action
    public async markFeedbackSurveyAsSkipped(): Promise<void> {
      await this.mutate((data) => {
        data.settings.feedbackAfterTenHours = null;
      });
    }

    @Action
    public async markFeedbackSurveyAsFilled(): Promise<void> {
      await this.mutate((data) => {
        data.settings.feedbackAfterTenHours = new Date().toISOString();
      });
    }

    @Action
    private async mutate(mutation: CollectionMutation<'userSettings'>): Promise<void> {
      return $shareDB.mutate({
        collection: 'userSettings',
        id: $user.id,
        source: OpSource.Store,
        mutation,
      });
    }
  }

  return BaseUserSettingsModule;
}

export type BaseUserSettingsModule = InstanceType<ReturnType<typeof userSettingsModuleFactory>>;
