import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiToggle = _resolveComponent("VuiToggle")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["button button-full reedsy-accented product-button button-sm", {
      'bordered neutral': !_ctx.selected,
    }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.selected = !_ctx.selected), ["stop"]))
  }, [
    _createTextVNode(_toDisplayString(_ctx.selected ? 'Add-on selected' : 'Select add-on') + " ", 1),
    _createVNode(_component_VuiToggle, {
      class: "borderless",
      "model-value": _ctx.selected
    }, null, 8, ["model-value"])
  ], 2))
}