<template>
  <component
    :is="isProduct ? 'button' : 'div'"
    class="feature-summary reedsy-accented"
    :class="{
      product: isProduct,
      'vui-tab-option': isTabOption, // Reuse some styling but without the full component
      active: isProduct && isActive,
      'being-removed': showEnabledInfo && isRemovingAddon,
      collapsable,
      'preview': isPreview
    }"
    @click="toggleSelected"
  >
    <h3
      class="flex-justified"
      @click="expanded = !expanded"
    >
      <div>
        <p
          v-if="showEnabledInfo"
          class="enabled-title"
        >
          {{ enabledInfo }}
        </p>
        {{ summary.title }}
      </div>

      <rbe-title-actions class="flex-center">
        <SubscriptionProductPrice
          v-if="isProduct && !isPreview"
          :prices="prices"
          :product="summary.product"
          :class="{
            'highlighted': selected && !collapsable,
          }"
          class="product-price"
        />
        <FreeFeatureBadge v-else-if="!isPreview" />

        <component
          :is="expanded ? 'VuiIconUp' : 'VuiIconDown'"
          v-if="collapsable"
        />
      </rbe-title-actions>
    </h3>
    <hr
      v-if="!collapsable"
      class="thin"
    >
    <ExpandTransition>
      <ul v-if="!collapsable || expanded">
        <li
          v-for="feature in summary.features"
          :key="feature"
        >
          {{ feature }}
        </li>
      </ul>
    </ExpandTransition>

    <span
      v-if="isPreview && prices"
      class="trial-price-info"
    >
      Free for {{ config.stripe.trialDays }} days, then
      <SubscriptionProductPrice
        class="price"
        :prices="prices"
        :product="summary.product"
      />
    </span>

    <FeatureToggle
      v-else-if="isProduct"
      v-model="selected"
      class="feature-toggle"
    />

    <rbe-removal-info v-if="showEnabledInfo && isRemovingAddon">
      <p>
        This add-on was removed from your subscription and
        will be disabled on {{ cancellationDate }}.
      </p>
    </rbe-removal-info>

    <VuiIconTick
      v-if="isTabOption"
      class="icon-tick"
    />
  </component>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import {config} from '@reedsy/studio.shared/config';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {IBillingInterval} from '@reedsy/utils.subscription';
import SubscriptionProductPrice from '@reedsy/studio.shared/components/subscriptions/subscription-product-price.vue';
import FreeFeatureBadge from './free-feature-badge.vue';
import FeatureToggle from './feature-toggle.vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';

@Component({components: {
  SubscriptionProductPrice,
  FreeFeatureBadge,
  FeatureToggle,
  ExpandTransition,
}})
export default class FeatureSummary extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Boolean, default: false})
  public isPreview: boolean;

  @Prop({type: Boolean, default: false})
  public showEnabledInfo: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  @Prop({type: Boolean, default: false})
  public collapsable: boolean;

  public readonly config = config;
  public expanded = true;

  public get isProduct(): boolean {
    return !!this.summary.product;
  }

  public get isTabOption(): boolean {
    return this.isProduct && !this.collapsable;
  }

  public get isActive(): boolean {
    return this.isPreview || this.selected;
  }

  public get currentSubscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get currentPeriodProducts(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.products);
  }

  public get nextPeriodProducts(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.nextBilling.products);
  }

  public get isRemovingAddon(): boolean {
    return this.currentPeriodProducts.has(this.summary.product) &&
    !this.nextPeriodProducts.has(this.summary.product);
  }

  public get cancellationDate(): string {
    return this.currentSubscription.nextBilling.date;
  }

  public get enabledInfo(): string {
    if (!this.isProduct) return 'Included';

    let enabledInfo = 'Enabled';

    if (this.isRemovingAddon) {
      enabledInfo += ` until ${this.cancellationDate}`;
    }

    return enabledInfo;
  }

  public toggleSelected(): void {
    if (!this.isProduct || this.collapsable) return;
    this.selected = !this.selected;
  }
}
</script>

<style lang="scss" scoped>
.feature-summary {
  --vui-tabOption-indicator-size: 3.5rem;
  --vui-tabOption-icon-size: 0.75rem;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  border-radius: $border-radius-lg;
  padding: $space-base;
  margin: 0;
  gap: 0;
  background-color: var(--reedsy-plain);
  box-sizing: border-box;

  &.product {
    border: $border-width-lg solid var(--reedsy-neutral);

    &.active,
    &:hover {
      border: $border-width-lg solid var(--local-color-default);
    }

    @media(hover: hover) {
      &:hover {
        --local-color-default: var(--local-color-emphasis);
      }
    }
  }

  &.preview {
    cursor: default;
    border: $border-width-lg solid var(--local-color-default);

    &:hover {
      --local-color-default: var(--reedsy-accent-premium);
    }
  }

  &.being-removed {
    border-style: dashed;
  }

  &:not(.product),
  &.active {
    box-shadow: $box-shadow-base;
  }

  &.collapsable {
    .product-price {
      @include font-family($controls, bold);
    }
  }

  ul {
    padding: 0;
    flex: 1 0 auto;
    width: 100%;

    li {
      @include font-family($controls);

      margin: $space-sm 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    width: 100%;

    .enabled-title {
      @include font-family($controls, normal);

      color: var(--local-color-default);
      font-size: $font-size-base;
    }

    rbe-title-actions {
      gap: $space-base;
    }

    .product-price {
      @include font-family($controls, normal);

      &.highlighted {
        color: var(--local-color-default);
      }
    }

    :deep(.skeleton-loader) {
      width: 6.5rem;
    }

    :deep(.interval) {
      font-size: $font-size-base;
    }
  }

  hr {
    width: 100%;
    margin: 0;
  }

  ul, h3, hr {
    &:not(:last-child) {
      margin-bottom: $space-base;
    }
  }

  .trial-price-info {
    @include font-family($controls, normal);

    .price {
      text-decoration: underline;
    }
  }

  &:hover :deep(.product-button .vui-toggle.borderless.checked .toggle) {
    background-color: var(--studio-subscriptions-featureSummary-toggle-backgroundColor-selected-hover);
  }

  rbe-removal-info {
    @include font-family($controls, normal);

    margin-top: $space-base;
  }

  .icon-tick {
    color: var(--reedsy-accent-premium-onDefault);
  }
}
</style>
