import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-top" }
const _hoisted_2 = {
  key: 0,
  class: "flex-centered"
}
const _hoisted_3 = { id: "stripe-payment-method" }
const _hoisted_4 = {
  key: 1,
  class: "flex-top payment-form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconArrowLeft = _resolveComponent("VuiIconArrowLeft")!
  const _component_SubscriptionPriceBreakdown = _resolveComponent("SubscriptionPriceBreakdown")!
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_PaymentMethodInfo = _resolveComponent("PaymentMethodInfo")!
  const _component_TwoColumnModal = _resolveComponent("TwoColumnModal")!

  return (_openBlock(), _createBlock(_component_TwoColumnModal, {
    id: _ctx.id,
    class: "subscription-payment-modal equal-columns reedsy-accented accent-premium full-height themed light-theme"
  }, {
    title: _withCtx(() => [
      _createElementVNode("rbe-subscription-back", null, [
        _createElementVNode("button", {
          type: "button",
          class: "back-button button-with-icon",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToPreview && _ctx.backToPreview(...args)))
        }, [
          _createVNode(_component_VuiIconArrowLeft),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _cache[3] || (_cache[3] = _createElementVNode("span", null, "Back", -1))
        ])
      ])
    ]),
    left: _withCtx(() => [
      _createElementVNode("rbe-subscription-payment", null, [
        _createElementVNode("h1", null, "\n          Confirm your " + _toDisplayString(_ctx.intervalTitle) + " subscription\n        ", 1),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _createVNode(_component_SubscriptionPriceBreakdown, {
          "calculated-price": _ctx.price,
          compact: ""
        }, null, 8, ["calculated-price"])
      ])
    ]),
    right: _withCtx(() => [
      (_ctx.shouldShowPaymentSetup)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            ref: "payment-form",
            class: "flex-top payment-form",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.subscribe && _ctx.subscribe(...args)), ["prevent"]))
          }, [
            _createVNode(_component_Panel, { class: "payment-method-panel" }, {
              default: _withCtx(() => [
                _createElementVNode("rbe-payment-methods-selector", _hoisted_1, [
                  (!_ctx.isAttached)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_VuiLoadingIndicator, { ref: "loader" }, null, 512)
                      ]))
                    : _createCommentVNode("", true),
                  _cache[5] || (_cache[5] = _createTextVNode()),
                  _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
                    [_vShow, _ctx.isAttached]
                  ])
                ])
              ]),
              _: 1
            }),
            _cache[7] || (_cache[7] = _createTextVNode()),
            _createVNode(_component_LoadingButton, {
              ref: "subscribe-button",
              loading: _ctx.isProcessingPayment,
              disabled: !_ctx.clientSecret,
              class: "button accent-premium",
              type: "submit"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("\n          Subscribe & enable add-ons\n        ")
              ])),
              _: 1
            }, 8, ["loading", "disabled"])
          ], 544))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Panel, { class: "payment-method-panel" }, {
              default: _withCtx(() => [
                _createVNode(_component_PaymentMethodInfo)
              ]),
              _: 1
            }),
            _cache[9] || (_cache[9] = _createTextVNode()),
            _createVNode(_component_LoadingButton, {
              ref: "update-subscription-button",
              loading: _ctx.isProcessingPayment,
              class: "button accent-premium",
              onClick: _ctx.updateSubscription
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("\n          Update subscription\n        ")
              ])),
              _: 1
            }, 8, ["loading", "onClick"])
          ]))
    ]),
    _: 1
  }, 8, ["id"]))
}