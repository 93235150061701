<template>
  <SkeletonLoader
    v-if="!text"
    class="placeholder-loader"
  />
  <span v-else>
    {{ text }}
  </span>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class TextSkeletonLoader extends ClientSharedVue {
  @Prop({type: String})
  public text: string;
}
</script>

<style lang="scss" scoped>
.placeholder-loader {
  height: 1em;
}
</style>
