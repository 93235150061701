
import {Component, mixins, Prop, Ref} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ModalCloseButton from '@reedsy/studio.shared/components/modals/components/modal-close-button.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';

@Component({
  components: {
    ModalCloseButton,
    PlainModal,
  },
})
export default class TwoColumnModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Browser)
  public readonly $browser: SharedBrowserModule;

  @Prop({type: String, default: ''})
  public title: string;

  @Ref('scrollContainer')
  private scrollContainer: HTMLElement;

  private displayShadow = false;
  private resizeObserver = new ResizeObserver(this.updateDisplayShadow);

  public get isMobile(): boolean {
    return this.$browser.screen.lessThan.md;
  }

  public mounted(): void {
    this.resizeObserver.observe(this.scrollContainer);
  }

  public beforeUnmount(): void {
    this.resizeObserver.disconnect();
  }

  public closeAll(): void {
    this._sharedModals.CLOSE_ALL();
  }

  public updateDisplayShadow(): void {
    const {scrollTop, scrollHeight, clientHeight} = this.scrollContainer;
    this.displayShadow = Math.ceil(scrollTop + clientHeight) < scrollHeight;
  }
}
