
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import SubscriptionPrice from './subscription-price.vue';
import {choosePrice} from '@reedsy/studio.shared/services/stripe/choose-price';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';

@Component({
  components: {
    SubscriptionPrice,
  },
})
export default class SubscriptionProductPrice extends ClientSharedVue {
  @Prop({type: String as PropType<SubscriptionProduct>})
  public product: SubscriptionProduct;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  public get monthlyPrice(): number {
    if (!this.prices) return;

    const {userCurrency, offerings} = this.prices;
    return choosePrice(userCurrency, offerings[this.product].month).price;
  }
}
