import {injectable, named} from 'inversify';
import {ISubscriptionModalService} from './i-subscription-modal-service';
import {$inject} from '@reedsy/studio.shared/types';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ISubscriptionFeaturesModalArgs} from '@reedsy/studio.shared/components/modals/components/subscription/features/subscription-features-modal-args.interface';
import {ISubscriptionPaymentModalArgs} from '@reedsy/studio.shared/components/modals/components/subscription/features/subscription-payment-modal-args.interface';
import {SUBSCRIPTION_PRODUCTS} from '@reedsy/utils.subscription';

@injectable()
export class SubscriptionModalService implements ISubscriptionModalService {
  @$inject('StoreModule')
  @named(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  @$inject('StoreModule')
  @named(SharedStoreName.User)
  public $user: SharedUserModule;

  @$inject('StoreModule')
  @named(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public open(options?: ISubscriptionFeaturesModalArgs): Promise<void> {
    if (this.$subscription.hasEverHadSubscription) {
      return this.openFeatures(options);
    } else {
      return this.$modals.open({type: 'SubscriptionTrial'});
    }
  }

  public openPayment(options: ISubscriptionPaymentModalArgs): Promise<void> {
    return this.$modals.open({
      type: 'SubscriptionPayment',
      context: options,
    });
  }

  public openCancelConfirmation(): Promise<void> {
    return this.$modals.open('ConfirmCancelSubscription');
  }

  public openPaymentSuccess(): Promise<void> {
    return this.$modals.open('SubscriptionPaymentSuccess');
  }

  private openFeatures(options?: ISubscriptionFeaturesModalArgs): Promise<void> {
    options ??= {
      products: new Set(SUBSCRIPTION_PRODUCTS),
    };

    return this.$modals.open({
      type: 'SubscriptionFeatures',
      context: options,
    });
  }
}
