<template>
  <button
    class="modal-close-button button button-icon button-sm reedsy-accented"
    type="button"
  >
    <VuiIconCross />
  </button>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class ModalCloseButton extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
.modal-close-button {
  &.with-shadow {
    box-shadow: $box-shadow-base;
  }
}
</style>
