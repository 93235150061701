import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {deepFreeze} from '@reedsy/utils.object';

const SYMBOL_MAPPING = deepFreeze({
  [SupportedCurrency.USD]: '$',
  [SupportedCurrency.GBP]: '£',
} as const satisfies Record<SupportedCurrency, string>);

export function getCurrencySymbol(currency: SupportedCurrency): string {
  return SYMBOL_MAPPING[currency] || '';
}
