<template>
  <SubscriptionStartedBase
    :id="id"
    :title="`Your ${config.stripe.trialDays}-day trial has started`"
  >
    <template #banner>
      <rbe-trial-started>
        <rbe-toggles class="flex-middle">
          <AddOnToggle
            v-for="addOnTitle in allAddonsTitles"
            :key="addOnTitle"
            :title="addOnTitle"
            :model-value="true"
            :disabled="true"
          />
        </rbe-toggles>
      </rbe-trial-started>
    </template>

    <span ref="description">
      Now’s your chance to develop a writing routine and explore our outlining features. Happy writing!
    </span>
  </SubscriptionStartedBase>
</template>

<script lang="ts">
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import AddOnToggle from './add-on-toggle.vue';
import {config} from '@reedsy/studio.shared/config';
import SubscriptionStartedBase from './subscription-started-base.vue';
import {FEATURE_SUMMARIES} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';
import {IProductSummary} from '@reedsy/studio.shared/components/modals/components/subscription/features/product-summary.interface';

@Component({
  components: {
    AddOnToggle,
    SubscriptionStartedBase,
  },
})
export default class SubscriptionTrialStarted extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({required: false, default: FEATURE_SUMMARIES})
  public featureSummaries: IProductSummary[];

  public readonly config = config;
  public readonly cancelable = true;

  public get allAddonsTitles(): string[] {
    return this.featureSummaries
      .filter((feature) => !!feature.product)
      .map((feature) => feature.title);
  }
}
</script>

<style lang="scss" scoped>
rbe-trial-started {
  width: 100%;
}

rbe-toggles {
  $size: 15rem;

  position: relative;
  height: $size;
  width: min(25rem, 100%);
  gap: $space-sm;
  margin: $space-base auto;

  & > * {
    position: relative;
  }

  @include screen-less-than(sm) {
    :deep(.toggle-label){
      display: none
    }
  }

  &::before {
    @include size($size);

    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--reedsy-neutral-muted);
    border-radius: 50%;
  }
}
</style>
