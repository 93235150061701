
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PeriodSummary from './period-summary.vue';
import {IBillingInterval} from '@reedsy/utils.subscription';

@Component({
  components: {PeriodSummary},
})
export default class BillingFrequencySelector extends ClientSharedVue {
  @Model({type: String, required: true})
  public interval: IBillingInterval;

  @Prop({type: Boolean, default: false})
  public disableMonthly: boolean;

  public get billAnnually(): boolean {
    return this.interval === 'year';
  }

  public set billAnnually(billAnnually: boolean) {
    this.interval = billAnnually ? 'year' : 'month';
  }
}
