import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (!_ctx.text)
    ? (_openBlock(), _createBlock(_component_SkeletonLoader, {
        key: 0,
        class: "placeholder-loader"
      }))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
}