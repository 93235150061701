
import {Component, mixins, Prop, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {PropType} from 'vue';
import {ISubscriptionFeaturesModalArgs} from './subscription-features-modal-args.interface';
import FeatureSummaries from './feature-summaries.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {objectKeys} from '@reedsy/utils.object';
import {IBillingInterval} from '@reedsy/utils.subscription';
import {DEFAULT_BILLING_INTERVAL} from './default-billing-interval';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import SubscriptionPrice from '@reedsy/studio.shared/components/subscriptions/subscription-price.vue';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import TwoColumnModal from '@reedsy/studio.shared/components/modals/components/two-column-modal.vue';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';
import BillingFrequencySelector from './billing-frequency-selector.vue';
import {deepEqual} from '@reedsy/utils.deep-equal';
import {clone} from '@reedsy/utils.clone';
import {setLoadingFlag} from '@reedsy/utils.disposable';
import BundleDiscountAmount from './bundle-discount-amount.vue';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';
import SubscriptionPriceBreakdown from '@reedsy/studio.shared/components/subscriptions/subscription-price-breakdown.vue';

@Component({
  components: {
    FeatureSummaries,
    SubscriptionPrice,
    TwoColumnModal,
    PremiumIcon,
    BillingFrequencySelector,
    BundleDiscountAmount,
    SubscriptionPriceBreakdown,
  },
})
export default class SubscriptionFeatures extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionFeaturesModalArgs>, default: {}})
  public context: ISubscriptionFeaturesModalArgs;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInjectStore(SharedStoreName.Browser)
  public readonly $browser: SharedBrowserModule;

  public readonly cancelable = true;
  public interval: IBillingInterval = DEFAULT_BILLING_INTERVAL;

  public isLoadingPrice: boolean = true;
  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>> = {};
  public calculatedPrice: ICalculatePriceResponse = null;
  public prices: IPriceOptionsResponse = null;

  public get isMobile(): boolean {
    return this.$browser.screen.lessThan.md;
  }

  public get hasSelectedAnything(): boolean {
    return Object.values(this.selectedProducts).some(Boolean);
  }

  public get hasActiveYearlySubscription(): boolean {
    return this.$subscription.currentSubscriptionInfo?.interval === 'year';
  }

  public get monthlyCalculatedPrice(): ICalculatePriceResponse {
    if (!this.calculatedPrice) return;
    if (this.calculatedPrice.interval === 'month') return this.calculatedPrice;
    const calculatedPrice = clone(this.calculatedPrice);
    objectKeys(calculatedPrice.products).forEach((key) => {
      calculatedPrice.products[key].amount = this.yearlyValueToMonthly(calculatedPrice.products[key].amount);
    });

    return {
      interval: 'month',
      currency: calculatedPrice.currency,
      products: calculatedPrice.products,
      discounts: calculatedPrice.discounts.
        map((discount) => ({...discount, amount: this.yearlyValueToMonthly(discount.amount)})),
      total: this.yearlyValueToMonthly(calculatedPrice.total),
      subtotal: this.yearlyValueToMonthly(calculatedPrice.subtotal),
      vatAmount: this.yearlyValueToMonthly(calculatedPrice.vatAmount),
    };
  }

  public get title(): string {
    return this.context.isPaywall ? 'Upgrade to access this feature!' : 'Ready to upgrade?';
  }

  public async created(): Promise<void> {
    this.prices = await this.$subscription.fetchPrice();
    const products = new Set(this.context.products);
    this.selectedProducts = {};
    for (const product of products) this.selectedProducts[product] = true;
  }

  public yearlyValueToMonthly(amount: number): number {
    return amount / 12;
  }

  @Watch('interval')
  @Watch('selectedProducts', {deep: true, immediate: true})
  public async subscriptionUpdated(): Promise<void> {
    using doneLoading = setLoadingFlag(this, 'isLoadingPrice');
    this.calculatedPrice = null;
    if (!this.hasSelectedAnything) return;

    const interval = this.interval;
    const selectedProducts = clone(this.selectedProducts);
    const products = objectKeys(selectedProducts)
      .filter((product) => selectedProducts[product]);

    const calculatedPrice = await this.$subscription.calculateSubscriptionPrice({
      interval,
      products,
    });

    if (this.interval === interval && deepEqual(selectedProducts, this.selectedProducts)) {
      this.calculatedPrice = calculatedPrice;
      return;
    }

    doneLoading();
  }

  public continueToPayment(): void {
    this.$subscriptionModal.openPayment({
      price: this.calculatedPrice,
    });
  }
}
