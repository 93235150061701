<template>
  <rbe-free-badge>
    Free for everyone
  </rbe-free-badge>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class FreeFeatureBadge extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
rbe-free-badge {
  @include font-family($controls, bold);

  font-size: $font-size-xs;
  line-height: 1;
  background-color: var(--local-color-muted);
  color: var(--local-color-onMuted);
  border-radius: 9999px;
  padding: $space-xs $space-sm;
}
</style>
