
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IBillingInterval} from '@reedsy/utils.subscription';

@Component({})
export default class PeriodSummary extends ClientSharedVue {
  @Prop({type: Boolean})
  public selected: boolean;

  @Prop({type: String})
  public interval: IBillingInterval;

  public get periodTitle(): string {
    return this.interval === 'year' ? 'Annually' : 'Monthly';
  }

  public get discount(): string {
    // TODO: [subscriptions] pull value from config?
    return this.interval === 'year' ? 'Save 10%' : 'No discount';
  }
}
