import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "sub-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumInfo = _resolveComponent("PremiumInfo")!
  const _component_PanelBodyTitle = _resolveComponent("PanelBodyTitle")!
  const _component_VuiTimeSelector = _resolveComponent("VuiTimeSelector")!
  const _component_ItemsBox = _resolveComponent("ItemsBox")!

  return (_openBlock(), _createElementBlock("rbe-check-in-notifications-settings", null, [
    _createVNode(_component_PanelBodyTitle, { class: "small" }, {
      "premium-info": _withCtx(() => [
        _createVNode(_component_PremiumInfo, { "paid-feature": _ctx.paidFeature }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.premiumDescriptions.checkIn), 1)
          ]),
          _: 1
        }, 8, ["paid-feature"])
      ]),
      default: _withCtx(() => [
        _cache[1] || (_cache[1] = _createTextVNode("\n      Check-in\n\n      "))
      ]),
      _: 1
    }),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createVNode(_component_ItemsBox, null, {
      default: _withCtx(() => [
        _createElementVNode("fieldset", {
          class: "settings-details",
          disabled: !_ctx.featureEnabled
        }, [
          _createElementVNode("label", null, [
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "\n            Check-in reminder time\n          ", -1)),
            _cache[3] || (_cache[3] = _createTextVNode()),
            _createElementVNode("span", _hoisted_2, "\n            Sent every day you write at " + _toDisplayString(_ctx.humanReadableTime) + ".\n            You can turn off check‑in notifications for a particular book in its settings.\n          ", 1)
          ]),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _createVNode(_component_VuiTimeSelector, {
            ref: "time-selector",
            modelValue: _ctx.checkInEmailTime,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkInEmailTime) = $event)),
            class: "accent-alt",
            "minutes-span": 15,
            disabled: !_ctx.featureEnabled || null
          }, null, 8, ["modelValue", "disabled"])
        ], 8, _hoisted_1)
      ]),
      _: 1
    })
  ]))
}