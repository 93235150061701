import sass from '@reedsy/studio.shared/style/_exported.scss';
import {AppTheme} from '@reedsy/studio.shared/store/modules/user-settings/app-theme';

const SASS = {
  breakpoint: {
    sm: parseFloat(sass['breakpoint-sm']),
    md: parseFloat(sass['breakpoint-md']),
    lg: parseFloat(sass['breakpoint-lg']),
  },
  transitionTime: {
    slow: transitionToMillis(sass['transition-slow']),
    base: transitionToMillis(sass['transition-base']),
    fast: transitionToMillis(sass['transition-fast']),
  },
  space: {
    xxxs: convertRemToPx(sass['space-xxxs']),
    xxs: convertRemToPx(sass['space-xxs']),
    xs: convertRemToPx(sass['space-xs']),
    sm: convertRemToPx(sass['space-sm']),
    md: convertRemToPx(sass['space-md']),
    base: convertRemToPx(sass['space-base']),
    lg: convertRemToPx(sass['space-lg']),
    xl: convertRemToPx(sass['space-xl']),
    xxl: convertRemToPx(sass['space-xxl']),
    xxxl: convertRemToPx(sass['space-xxxl']),
  },
  borderRadius: {
    xs: parseFloat(sass['borderRadius-xs']),
    sm: parseFloat(sass['borderRadius-sm']),
    base: parseFloat(sass['borderRadius-base']),
    lg: parseFloat(sass['borderRadius-lg']),
    xl: parseFloat(sass['borderRadius-xl']),
    xxl: parseFloat(sass['borderRadius-xxl']),
  },
  size: {
    widgetHeight: parseFloat(sass['size-widget-height']),
    widgetHeaderHeight: convertRemToPx(sass['size-widget-headerHeight']),
    checkInMinHeight: convertRemToPx(sass['size-checkIn-minHeight']),
  },
  userColorPoolSize: parseInt(sass['userColorPoolSize']),
  mainThemeColors: {
    [AppTheme.Light]: sass['mainThemeColors-light'],
    [AppTheme.Dark]: sass['mainThemeColors-dark'],
  } satisfies Record<AppTheme, string>,
};

function transitionToMillis(time: string): number {
  const seconds = +(time.split('s')[0]);
  return seconds * 1000;
}

export function convertRemToPx(value: string): number {
  return parseFloat(sass['rem']) * parseFloat(value);
}

export default SASS;
