<template>
  <ExpandTransition>
    <rbe-premium-ad-banner-wrapper v-if="shouldShow">
      <button
        ref="open-premium-modal-button"
        class="premium-ad-banner"
        type="button"
        @click="openPremiumModal"
      >
        <span ref="message">{{ message }}</span>
        <PremiumBadge
          size="xs"
          class="premium-badge"
        >
          {{ badgeText }}
        </PremiumBadge>
      </button>
    </rbe-premium-ad-banner-wrapper>
  </ExpandTransition>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import TrialCountdownMixin from '@reedsy/studio.shared/mixins/subscriptions/trial-countdown';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import PremiumBadge from './premium-badge.vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';

@Component({
  components: {
    PremiumBadge,
    ExpandTransition,
  },
})
export default class PremiumAdBanner extends mixins(ClientSharedVue, TrialCountdownMixin) {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInject('Navigation')
  public $navigation: INavigation;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public get message(): string {
    if (this.$subscription.isTrial) {
      return this.trialMessage;
    }

    return 'Explore our premium add-ons';
  }

  public get badgeText(): string {
    if (!this.$subscription.hasEverHadSubscription) {
      return 'Start free trial';
    }

    return 'Upgrade';
  }

  public get shouldShow(): boolean {
    if (!this.$subscription.hasCurrentSubscriptionBeenInitialised) return false;
    if (this.$subscription.currentSubscriptionInfo?.isCancelling) return true;
    if (this.$subscription.isTrial) {
      return !this.$subscription.hasPaymentMethodSet;
    }

    return !this.$subscription.hasAnyPaidFeature;
  }

  public openPremiumModal(): void {
    this.$subscriptionModal.open();
  }
}
</script>

<style lang="scss" scoped>
rbe-premium-ad-banner-wrapper {
  width: 100%;

  &.high-contrast .premium-badge :deep(rbe-premium-badge){
    background-color: var(--studio-premiumAddBannerBadge-backgroundColor);
  }

  &.with-badge-padding .premium-badge :deep(rbe-premium-badge) {
    padding: $space-xs;
  }
}

.premium-ad-banner {
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  gap: $space-md;
  padding: $space-md $space-base;
  font-size: $font-size-sm;

  @include container-less-than(sm) {
    gap: $space-sm;
    padding: $space-sm $space-base;
    font-size: $font-size-xs;
  }
}
</style>
