<template>
  <PlainModal
    :id="id"
    class="accent-premium transparent gradient-overlay"
    headless
  >
    <rbe-welcome-section class="flex-centered">
      <rbe-premium-icon>
        <rbe-premium-icon class="ping" />
        <VuiIconCrown />
      </rbe-premium-icon>

      <rbe-welcome-text class="flex-centered">
        <h1>
          Welcome to the club<span v-if="userName">, {{ userName }}</span>
        </h1>

        <p>
          Your add-ons have been unlocked.
        </p>

        <p>
          Thank you for subscribing and happy writing!
        </p>
      </rbe-welcome-text>

      <button
        class="button reedsy-accented"
        type="button"
        @click="close"
      >
        Continue
      </button>
    </rbe-welcome-section>
  </PlainModal>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import capitalize from 'capitalize';

@Component({
  components: {
    PlainModal,
  },
})
export default class SubscriptionPaymentSuccess extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  public readonly cancelable = true;

  public get userName(): string {
    return capitalize(this.$user.info.firstName || '');
  }
}
</script>

<style lang="scss" scoped>
@keyframes ping {
  75%, 100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

rbe-welcome-section {
  gap: $space-xxxl;

  rbe-premium-icon {
    position: relative;
    display: flex;
    color: var(--reedsy-accent-premium-onDefault);
    background: var(--reedsy-accent-premium);
    border-radius: 50%;
    padding: $space-xxxl;

    .vui-icon {
      @include size(3.25rem);
    }

    .ping {
      animation: ping 2s cubic-bezier(0,0,.2,1) infinite;
      position: absolute;
      inset: 0;
    }
  }

  rbe-welcome-text {
    h1 {
      margin-bottom: $space-sm;
    }

    gap: $space-xs;
  }
}
</style>
