
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import FeatureSummary from './feature-summary.vue';
import {FEATURE_SUMMARIES} from './feature-summaries';
import {IProductSummary} from './product-summary.interface';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {PropType} from 'vue';
import {IBillingInterval} from '@reedsy/utils.subscription';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';

@Component({
  components: {
    FeatureSummary,
  },
})
export default class FeatureSummaries extends ClientSharedVue {
  @Model({type: Object, default: {}})
  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>>;

  @Prop({type: Boolean, default: false})
  public featurePreview: boolean;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  @$lazyInjectStore(SharedStoreName.Browser)
  public readonly $browser: SharedBrowserModule;

  public get summaries(): ReadonlyArray<IProductSummary> {
    if (!this.featurePreview) return FEATURE_SUMMARIES;

    return FEATURE_SUMMARIES.filter((summary) => summary.product);
  }

  public get showMobileVersion(): boolean {
    return this.$browser.screen.lessThan.md;
  }
}
