import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-center"
}
const _hoisted_2 = { class: "flex-justified" }
const _hoisted_3 = { class: "flex-top" }
const _hoisted_4 = { class: "book-owner-name" }
const _hoisted_5 = { class: "book-title" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "flex-justified" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "accept-invitation-panel" }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-panel-content", null, [
        _createVNode(_component_Logo, {
          "text-only": "",
          "with-app-name": ""
        }),
        _cache[14] || (_cache[14] = _createTextVNode()),
        _cache[15] || (_cache[15] = _createElementVNode("hr", null, null, -1)),
        _cache[16] || (_cache[16] = _createTextVNode()),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("rbe-loading-indicator-wrapper", _hoisted_1, [
              _createVNode(_component_VuiLoadingIndicator)
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("rbe-invitation-info", _hoisted_2, [
                _createElementVNode("rbe-user-avatar", null, [
                  _createVNode(_component_Avatar, {
                    "user-info": _ctx.$user.info,
                    bordered: "",
                    "no-badge": "",
                    class: "xl"
                  }, null, 8, ["user-info"])
                ]),
                _cache[8] || (_cache[8] = _createTextVNode()),
                _createElementVNode("rbe-text-wrapper", _hoisted_3, [
                  _createElementVNode("h1", null, [
                    _cache[2] || (_cache[2] = _createTextVNode("You’ve been granted access to ")),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.bookOwnerName), 1),
                    _cache[3] || (_cache[3] = _createTextVNode("’s book"))
                  ]),
                  _cache[7] || (_cache[7] = _createTextVNode()),
                  _createElementVNode("p", null, [
                    _cache[4] || (_cache[4] = _createTextVNode("\n              Accept the invitation to access\n              ‘")),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.bookTitle), 1),
                    _cache[5] || (_cache[5] = _createTextVNode("’\n              using the Reedsy Account registered under\n              ")),
                    _createElementVNode("a", {
                      href: `mailto:${_ctx.currentUserEmail}`,
                      class: "link reversed user-email"
                    }, _toDisplayString(_ctx.currentUserEmail), 9, _hoisted_6),
                    _cache[6] || (_cache[6] = _createTextVNode(".\n            "))
                  ])
                ])
              ]),
              _cache[11] || (_cache[11] = _createTextVNode()),
              _cache[12] || (_cache[12] = _createElementVNode("hr", null, null, -1)),
              _cache[13] || (_cache[13] = _createTextVNode()),
              _createElementVNode("rbe-footer", _hoisted_7, [
                _createElementVNode("button", {
                  class: "change-account-button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.$invitationApprovalFlow.changeAccount && _ctx.$invitationApprovalFlow.changeAccount(...args)))
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("span", { class: "link" }, "Sign in with a different account", -1)
                ])),
                _cache[10] || (_cache[10] = _createTextVNode()),
                _createElementVNode("button", {
                  class: "button reedsy-accented accept-invitation-button",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.$invitationApprovalFlow.acceptInvitation && _ctx.$invitationApprovalFlow.acceptInvitation(...args)))
                }, "\n            Accept invitation\n          ")
              ])
            ], 64))
      ])
    ]),
    _: 1
  }))
}