import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-justified" }
const _hoisted_2 = { class: "flex-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "bordered trial-panel" }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-trial-panel-body", _hoisted_1, [
        _createElementVNode("rbe-trial-description", _hoisted_2, [
          _createElementVNode("h1", null, "Start your " + _toDisplayString(_ctx.trailDays) + "‑day free trial", 1),
          _cache[1] || (_cache[1] = _createTextVNode()),
          _createElementVNode("p", null, "Enable all premium add‑ons for " + _toDisplayString(_ctx.trailDays) + " days.", 1)
        ]),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createElementVNode("button", {
          ref: "start-trial-button",
          class: "reedsy-accented button",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPremiumModal && _ctx.openPremiumModal(...args)))
        }, "\n        Start " + _toDisplayString(_ctx.trailDays) + "‑day trial\n      ", 513)
      ])
    ]),
    _: 1
  }))
}