
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import SubscriptionBaseModal from './subscription-base.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {setLoadingFlag} from '@reedsy/utils.disposable';
import {config} from '@reedsy/studio.shared/config';
import {IPriceOfferings, IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import DelayedLoadingIndicator from '@reedsy/studio.shared/components/loader/delayed-loading-indicator.vue';
import FeatureSummaries from './features/feature-summaries.vue';
import CurrencySelector from './currency-selector.vue';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';

@Component({
  components: {
    DelayedLoadingIndicator,
    LoadingButton,
    SubscriptionBaseModal,
    FeatureSummaries,
    CurrencySelector,
  },
})
export default class SubscriptionTrial extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public readonly config = config;
  public readonly cancelable = true;

  public loading = false;
  public offerings: IPriceOfferings = null;
  public selectedCurrency: SupportedCurrency = SupportedCurrency.USD;

  public get prices(): IPriceOptionsResponse {
    return {
      offerings: this.offerings,
      userCurrency: this.selectedCurrency,
    };
  }

  public async created(): Promise<void> {
    const {offerings, userCurrency} = await this.$subscription.fetchPrice();
    this.offerings = offerings;
    this.selectedCurrency = userCurrency;
  }

  public async startTrial(): Promise<void> {
    using doneLoading = setLoadingFlag(this, 'loading');
    await this.$subscription.startTrial(this.selectedCurrency);
    doneLoading();
    this.close();
    this.$modals.open('SubscriptionTrialStarted');
  }
}
