import {logErrors} from '@reedsy/reedsy-logger-js';
import {Collection} from '@reedsy/reedsy-sharedb/lib/common/collection-types';
import {PresenceChannel} from '@reedsy/studio.isomorphic/models/presence-channel';
import {Source} from '@reedsy/studio.isomorphic/models/source';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import IShareDb from '@reedsy/studio.shared/services/sharedb/i-sharedb';
import {IOpDebounceOptions} from '@reedsy/studio.shared/store/modules/sharedb/i-op-debounce-options';
import {time} from '@reedsy/utils.date';
import {deepEqual} from '@reedsy/utils.deep-equal';
import {last} from '@reedsy/utils.iterable';
import {isEmpty} from '@reedsy/utils.object';
import {Doc} from 'sharedb';
import {Presence} from 'sharedb/lib/client';

const KEY_DELIMITER = ':';
const logger = loggerFactory.create('OpDebouncer');

export class OpDebouncer<T extends Collection> {
  private debounceStart: Record<string, Date> = {};
  private debounceTimeouts: Record<string, number> = {};

  public constructor(
    private readonly shareDB: IShareDb<T>,
    private readonly options: IOpDebounceOptions,
  ) {
    // The WebSocket will proactively disconnect on visibilitychange, so let's try
    // to flush the changes before that happens
    document.addEventListener('visibilitychange', this.resumeAll.bind(this));
  }

  private get hasRemotePresence(): boolean {
    const remotePresences = this.sidebarPresence?.remotePresences || {};
    return !isEmpty(remotePresences);
  }

  private get shouldPause(): boolean {
    return !this.options.disabled &&
      !!this.options.debounceMs &&
      // Don't debounce when collaborating, so that presence/doc updates are smooth
      // and to reduce the risk of gnarly merge conflicts
      !this.hasRemotePresence;
  }

  private get sidebarPresence(): Presence {
    const connection = this.shareDB.connection as any;
    // We don't have easy access to the Book ID here, so let's find the sidebar
    // presence by inspecting keys: there should only be one.
    const key = Object.keys(connection._presences).find((k) => k.endsWith(PresenceChannel.SidebarPresence));
    return connection._presences[key];
  }

  @logErrors(logger, {swallow: true})
  public touch(collection: T, id: string, source: Source): void {
    const doc = this.shareDB.get(collection, id);
    if (!this.shouldPause) return;

    const pendingSource = this.pendingSource(doc);
    const cannotCompose = pendingSource && !deepEqual(pendingSource, source || true);

    if (this.hasExceededMaxDebounce(doc) || cannotCompose) {
      this.resume(doc);
    }

    this.pause(doc);
  }

  private pause(doc: Doc): void {
    const key = this.docKey(doc);
    this.debounceStart[key] ||= new Date();
    doc.pause();

    clearTimeout(this.debounceTimeouts[key]);
    this.debounceTimeouts[key] = setTimeout(() => {
      this.resume(doc);
    }, this.options.debounceMs);
  }

  private resume(doc: Doc): void {
    doc.resume();
    const key = this.docKey(doc);
    clearTimeout(this.debounceTimeouts[key]);
    delete this.debounceTimeouts[key];
    delete this.debounceStart[key];
  }

  private resumeAll(): void {
    for (const key in this.debounceTimeouts) {
      const [collection, id] = key.split(KEY_DELIMITER);
      const doc = this.shareDB.connection.get(collection, id);
      this.resume(doc);
    }
  }

  private hasExceededMaxDebounce(doc: Doc): boolean {
    const key = this.docKey(doc);
    const start = this.debounceStart[key];
    if (!start) return false;
    return time.since(start) + this.options.debounceMs > this.options.maxDebounceMs;
  }

  private pendingSource(doc: any): Source | true {
    const pendingOp = last(doc.pendingOps) as any;
    return pendingOp?.source;
  }

  private docKey(doc: Doc): string {
    return [doc.collection, doc.id].join(KEY_DELIMITER);
  }
}
