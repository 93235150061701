import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatureSummary = _resolveComponent("FeatureSummary")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["feature-summaries", {
      'mobile-version': _ctx.showMobileVersion,
    }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries, (summary) => {
      return (_openBlock(), _createBlock(_component_FeatureSummary, {
        key: summary.product,
        ref_for: true,
        ref: summary.product,
        modelValue: _ctx.selectedProducts[summary.product],
        "onUpdate:modelValue": ($event: any) => ((_ctx.selectedProducts[summary.product]) = $event),
        summary: summary,
        interval: _ctx.interval,
        prices: _ctx.prices,
        "is-preview": _ctx.featurePreview,
        collapsable: _ctx.showMobileVersion
      }, null, 8, ["modelValue", "onUpdate:modelValue", "summary", "interval", "prices", "is-preview", "collapsable"]))
    }), 128))
  ], 2))
}