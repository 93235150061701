import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex-centered" }
const _hoisted_4 = {
  key: 0,
  class: "flex-center"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  ref: "billing-description",
  class: "billing-description"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "billing-description" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DelayedLoadingIndicator = _resolveComponent("DelayedLoadingIndicator")!
  const _component_VuiIconTick = _resolveComponent("VuiIconTick")!
  const _component_PeriodToggle = _resolveComponent("PeriodToggle")!
  const _component_FeatureSummary = _resolveComponent("FeatureSummary")!
  const _component_SubscriptionPriceBreakdown = _resolveComponent("SubscriptionPriceBreakdown")!
  const _component_SubscriptionPrice = _resolveComponent("SubscriptionPrice")!
  const _component_DiscountAmount = _resolveComponent("DiscountAmount")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("rbe-loading", _hoisted_1, [
        _createVNode(_component_DelayedLoadingIndicator, {
          class: "accent-primary",
          delay: 100
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("rbe-manage-addons-panel", null, [
          _createElementVNode("rbe-addon-selector", _hoisted_3, [
            (_ctx.hasActiveYearlySubscription)
              ? (_openBlock(), _createElementBlock("rbe-yearly-interval", _hoisted_4, [
                  _createVNode(_component_VuiIconTick, { class: "tick-icon icon-circle" }),
                  _cache[3] || (_cache[3] = _createTextVNode("\n          Billed annually\n        "))
                ]))
              : (_openBlock(), _createBlock(_component_PeriodToggle, {
                  key: 1,
                  modelValue: _ctx.billAnnually,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billAnnually) = $event))
                }, null, 8, ["modelValue"])),
            _cache[4] || (_cache[4] = _createTextVNode()),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSummaries, (summary) => {
              return (_openBlock(), _createBlock(_component_FeatureSummary, {
                key: summary.product,
                ref_for: true,
                ref: summary.product,
                modelValue: _ctx.activeAddons[summary.product],
                "onUpdate:modelValue": ($event: any) => ((_ctx.activeAddons[summary.product]) = $event),
                class: "feature-summary",
                summary: summary,
                interval: _ctx.selectedInterval,
                prices: _ctx.prices,
                collapsable: "",
                "show-enabled-info": ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "summary", "interval", "prices"]))
            }), 128))
          ]),
          _cache[16] || (_cache[16] = _createTextVNode()),
          (_ctx.showPriceBreakdown)
            ? (_openBlock(), _createElementBlock("rbe-update-subscription", _hoisted_5, [
                _createVNode(_component_SubscriptionPriceBreakdown, {
                  class: "price-info",
                  "calculated-price": _ctx.calculatedPrice,
                  "next-billing-date": _ctx.currentSubscription?.nextBilling.date,
                  loading: "",
                  "hide-next-billing-info": !_ctx.hasSubscriptionChanged
                }, null, 8, ["calculated-price", "next-billing-date", "hide-next-billing-info"]),
                _cache[14] || (_cache[14] = _createTextVNode()),
                (_ctx.hasSubscriptionChanged)
                  ? (_openBlock(), _createElementBlock("rbe-price-confirm", _hoisted_6, [
                      (_ctx.currentSubscription && _ctx.calculatedPrice)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                            (_ctx.isTrial)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode("\n              Starting from " + _toDisplayString(_ctx.currentSubscription.nextBilling.date) + " we will charge you\n              ", 1),
                                  _createVNode(_component_SubscriptionPrice, {
                                    price: _ctx.calculatedPrice.total,
                                    currency: _ctx.calculatedPrice.currency,
                                    interval: _ctx.calculatedPrice.interval
                                  }, null, 8, ["price", "currency", "interval"]),
                                  _cache[5] || (_cache[5] = _createTextVNode(".\n            "))
                                ], 64))
                              : (_ctx.currentSubscription && _ctx.billingPeriodHasChanged && _ctx.isAddingAddon)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _cache[6] || (_cache[6] = _createTextVNode("\n              You will be charged ")),
                                    _createVNode(_component_SubscriptionPrice, {
                                      price: _ctx.calculatedPrice.total,
                                      currency: _ctx.calculatedPrice.currency
                                    }, null, 8, ["price", "currency"]),
                                    _cache[7] || (_cache[7] = _createTextVNode(" immediately, with a deduction for any unused time left\n              from your current subscription, then ")),
                                    _createVNode(_component_SubscriptionPrice, {
                                      price: _ctx.calculatedPrice.total,
                                      currency: _ctx.calculatedPrice.currency,
                                      interval: _ctx.calculatedPrice.interval
                                    }, null, 8, ["price", "currency", "interval"]),
                                    _cache[8] || (_cache[8] = _createTextVNode(".\n            "))
                                  ], 64))
                                : (_ctx.currentSubscription && !_ctx.billingPeriodHasChanged && _ctx.isAddingAddon)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                      _cache[9] || (_cache[9] = _createTextVNode("\n              You will be charged prorated amount immediately for the added add-on, then ")),
                                      _createVNode(_component_SubscriptionPrice, {
                                        price: _ctx.calculatedPrice.total,
                                        currency: _ctx.calculatedPrice.currency,
                                        interval: _ctx.calculatedPrice.interval
                                      }, null, 8, ["price", "currency", "interval"]),
                                      _cache[10] || (_cache[10] = _createTextVNode(".\n            "))
                                    ], 64))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                      _createTextVNode("\n              On " + _toDisplayString(_ctx.currentSubscription.nextBilling.date) + " your subscription will change from\n              ", 1),
                                      _createVNode(_component_SubscriptionPrice, {
                                        price: _ctx.currentSubscription.nextBilling.price,
                                        currency: _ctx.currentSubscription.currency,
                                        interval: _ctx.currentSubscription.interval
                                      }, null, 8, ["price", "currency", "interval"]),
                                      _cache[11] || (_cache[11] = _createTextVNode(" to\n              ")),
                                      _createVNode(_component_SubscriptionPrice, {
                                        price: _ctx.calculatedPrice.total,
                                        currency: _ctx.calculatedPrice.currency,
                                        interval: _ctx.calculatedPrice.interval
                                      }, null, 8, ["price", "currency", "interval"]),
                                      _cache[12] || (_cache[12] = _createTextVNode(".\n              You can continue to use existing add-ons until that date.\n            "))
                                    ], 64))
                          ], 512))
                        : _createCommentVNode("", true),
                      _cache[13] || (_cache[13] = _createTextVNode()),
                      _createElementVNode("button", {
                        ref: "updateSubscriptionButton",
                        type: "button",
                        class: "button reedsy-accented",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.continueToPayment && _ctx.continueToPayment(...args)))
                      }, _toDisplayString(_ctx.currentSubscription ? 'Update subscription' : 'Continue to payment'), 513)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_ctx.cancelling && !_ctx.cancelled)
              ? (_openBlock(), _createElementBlock("rbe-update-subscription", _hoisted_8, [
                  _createElementVNode("rbe-price-confirm", null, [
                    _createElementVNode("span", _hoisted_9, "\n            You can continue to use existing add-ons until " + _toDisplayString(_ctx.currentSubscription.nextBilling.date) + ".\n          ", 1),
                    _cache[15] || (_cache[15] = _createTextVNode()),
                    _createElementVNode("button", {
                      ref: "cancelSubscriptionButton",
                      type: "button",
                      class: "button reedsy-accented danger",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openConfirmCancelModal && _ctx.openConfirmCancelModal(...args)))
                    }, "\n            Cancel subscription\n          ", 512)
                  ])
                ]))
              : _createCommentVNode("", true)
        ]),
        _cache[18] || (_cache[18] = _createTextVNode()),
        (_ctx.availableSummaries.length > 1)
          ? (_openBlock(), _createElementBlock("rbe-additional-savings", _hoisted_10, [
              _createVNode(_component_DiscountAmount, { "discount-type": "bundle" }),
              _cache[17] || (_cache[17] = _createTextVNode("\n      when you purchase multiple add-ons\n    "))
            ]))
          : _createCommentVNode("", true)
      ]))
}