import {AxiosError, AxiosResponse, RawAxiosRequestConfig} from 'axios';
import {config} from '@reedsy/studio.shared/config';
import {IBookInfo} from '@reedsy/studio.shared/models/i-book-info';
import {injectable} from 'inversify';
import {$inject} from '@reedsy/studio.shared/types';
import IApi, {IBaseInvitationRequest} from './i-api';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {HTTPStatus} from '@reedsy/utils.http';
import IImageResponse from '@reedsy/studio.shared/models/i-image-response';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import debug from '@reedsy/studio.shared/utils/debug/debug';
import {ICreateSnapshotRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/books/user-snapshots/create-request';
import {IUpdateSnapshotRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/books/user-snapshots/update-request';
import {IUserSnapshot} from '@reedsy/studio.isomorphic/controllers/api/v1/books/user-snapshots/i-user-snapshot';
import {IBookEdit} from '@reedsy/studio.isomorphic/controllers/api/v1/books/edits/i-book-edit';
import parseDates from './parse-dates';
import {IWordCountSum} from '@reedsy/studio.isomorphic/controllers/api/v1/books/word-count/i-word-count-sum';
import HTTPClient from '@reedsy/studio.shared/services/http-client/http-client';
import HttpMethod from '@reedsy/studio.shared/services/http-client/http-method';
import {IListShareableUrlsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/books/shareable-urls/list-response';
import {ICreateShareableUrlRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/books/shareable-urls/create-request';
import {IShareableUrlWithBuiltUrl} from '@reedsy/studio.isomorphic/controllers/api/v1/books/shareable-urls/i-shareable-url-with-built-url';
import {IEditShareableUrlRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/books/shareable-urls/edit-request';
import {IReaderBook} from '@reedsy/studio.isomorphic/controllers/api/reader/book/reader-book-info';
import {IGetRichTextReferencesResponse} from '@reedsy/studio.isomorphic/controllers/api/reader/book/i-get-rich-text-references-response';
import {IGetEndnotesResponse} from '@reedsy/studio.isomorphic/controllers/api/reader/book/i-get-endnotes-response';
import {IDisplayedCollaborator} from '@reedsy/studio.isomorphic/controllers/api/v1/users/displayed-collaborator';
import {ITotalWordCountResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/books/word-count/i-total-word-count-response';
import {IExportPayload} from '@reedsy/studio.isomorphic/models/export-request';
import {IUpdateBookRequest} from '@reedsy/studio.isomorphic/models/update-book-request';
import {ICreateBookRequest} from '@reedsy/studio.isomorphic/models/create-book-request';
import {IImportRequest} from './i-import-data';
import {IImportResponse} from '@reedsy/studio.isomorphic/models/imports/import-response';
import {IImportAlreadyUploadedFileRequest} from '@reedsy/studio.isomorphic/models/imports/import-already-uploaded-file';
import {IInvitationInfo} from '@reedsy/studio.isomorphic/controllers/api/v1/books/invitations/i-invitation-info.js';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import {IPatchBookDetailsRequest} from '@reedsy/studio.isomorphic/models/patch-book-details-request';
import {IAnalyticsEventsResponse} from '@reedsy/studio.isomorphic/models/analytics-events-response.interface';
import {IAnalyticsEventsRequest} from '@reedsy/studio.isomorphic/models/analytics-events-request';
import {ISubscriptionInfoResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-subscription-info-response';
import {ICalculatePriceRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/calculate-price-request';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import {ILoadingTimingRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/timing/loading-timing-request';
import {IPaymentMethodInfo} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-payment-info';

@injectable()
export default class Api extends HTTPClient implements IApi {
  private apiPath: string;
  private validationHeader: string;

  public constructor(
    @$inject('LoggerFactory') loggerFactory: LoggerFactory,
  ) {
    super();
    this.apiPath = config.app.server.api;
    this.validationHeader = config.api.validationHeader;
    this.logger = loggerFactory.create('Api');
  }

  public createBook(request: ICreateBookRequest): Promise<IBookInfo> {
    return this.post<IBookInfo>('books/', request);
  }

  public async importBook({importFile, title, subtitle, options}: IImportRequest): Promise<IImportResponse> {
    const payload = new FormData();
    payload.append('importFile', importFile);
    payload.append('title', title);

    if (subtitle) {
      payload.append('subtitle', subtitle);
    }
    return this.post<IImportResponse>('imports/', payload, options);
  }

  public async importAlreadyUploadedFile(payload: IImportAlreadyUploadedFileRequest): Promise<IImportResponse> {
    return this.post<IImportResponse>('imports/bucket', payload);
  }

  public getBook(id: string): Promise<IBookInfo> {
    return this.get<IBookInfo>(`books/${id}`);
  }

  public restoreBook(id: string, time: Date): Promise<void> {
    return this.put(`books/${id}/live-version`, {time});
  }

  public deleteBook(id: string): Promise<void> {
    return this.delete(`books/${id}`);
  }

  public async getCurrentUser(): Promise<IUserInfo> {
    return this.get<IUserInfo>('user');
  }

  public async fetchUserAnalyticsEvents(): Promise<IAnalyticsEventsResponse> {
    return this.get('user/ram');
  }

  public async markUserAnalyticsEventsAsSent(payload: IAnalyticsEventsRequest): Promise<void> {
    try {
      await this.post('user/ram', payload);
    } catch (error) {
      this.logger.warn('Could not mark events as sent', {error});
    }
  }

  public getSnapshots(bookId: string, start: Date, end: Date): Promise<IUserSnapshot[]> {
    return this.get(`books/${bookId}/user-snapshots?start=${start.toISOString()}&end=${end.toISOString()}`);
  }

  public createSnapshot(bookId: string, title: string, time: Date, userIds: string[]): Promise<IUserSnapshot> {
    const request: ICreateSnapshotRequest = {title, favourite: true, time, userIds};
    return this.post(`books/${bookId}/user-snapshots`, request);
  }

  public updateSnapshot(bookId: string, id: string, title: string, favourite: boolean): Promise<IUserSnapshot> {
    const request: IUpdateSnapshotRequest = {title, favourite};
    return this.patch(`books/${bookId}/user-snapshots/${id}`, request);
  }

  public updateBook(bookId: string, body: IUpdateBookRequest): Promise<void> {
    return this.patch(`books/${bookId}/`, body);
  }

  public getEditTimes(bookId: string, start: Date, end: Date): Promise<IBookEdit[]> {
    return this.get(`books/${bookId}/edits?start=${start.toISOString()}&end=${end.toISOString()}`);
  }

  public async getUserInfo(bookId: string, userId: string): Promise<IDisplayedCollaborator> {
    return this.get<IDisplayedCollaborator>(`books/${bookId}/collaborators/${userId}`);
  }

  public listCollaborators(bookId: string): Promise<IDisplayedCollaborator[]> {
    return this.get(
      `books/${bookId}/collaborators`,
    );
  }

  public revokeAccess(bookId: string, userUuid: string): Promise<void> {
    return this.delete(
      `books/${bookId}/collaborators/${userUuid}`,
    );
  }

  public revokeMyAccess(bookId: string): Promise<void> {
    return this.delete(
      `books/${bookId}/collaborators/me`,
    );
  }

  public async uploadBookCover(bookId: string, file: Blob, options?: RawAxiosRequestConfig): Promise<string> {
    const payload = new FormData();
    payload.append('imageFile', file);
    const data = await this.put<IImageResponse>(`books/${bookId}/images/cover`, payload, options);
    return data.url;
  }

  public async uploadBookCoverFromUrl(bookId: string, url: string, options?: RawAxiosRequestConfig): Promise<string> {
    const data = await this.put<IImageResponse>(`books/${bookId}/images/cover`, {imageSrc: url}, options);
    return data.url;
  }

  public async postImage(bookId: string, file: Blob, options?: RawAxiosRequestConfig): Promise<string> {
    const payload = new FormData();
    payload.append('imageFile', file);
    const data = await this.post<IImageResponse>(`books/${bookId}/images`, payload, options);
    return data.url;
  }

  public async postExternalImage(bookId: string, url: string, options?: RawAxiosRequestConfig): Promise<string> {
    const data = await this.post<IImageResponse>(`books/${bookId}/images`, {imageSrc: url}, options);
    return data.url;
  }

  public async deleteBookCover(bookId: string): Promise<void> {
    return await this.delete(`books/${bookId}/images/cover`);
  }

  public getAppVersion(): Promise<string> {
    return this.get('version');
  }

  public getWordCountChangeSum(bookId: string, from: Date, to: Date): Promise<IWordCountSum[]> {
    return this.get(`books/${bookId}/word-count/changes/sum?from=${from.toISOString()}&to=${to.toISOString()}`);
  }

  public getTotalWordCount(bookId: string): Promise<ITotalWordCountResponse> {
    return this.get(`books/${bookId}/word-count/total`);
  }

  public getShareableUrls(bookId: string): Promise<IListShareableUrlsResponse> {
    return this.get(`books/${bookId}/shareable-urls/`);
  }

  public createShareableUrl(bookId: string, body: ICreateShareableUrlRequest): Promise<IShareableUrlWithBuiltUrl> {
    return this.post(`books/${bookId}/shareable-urls/`, body);
  }

  public exportBook(bookId: string, body: IExportPayload): Promise<void> {
    return this.post(`books/${bookId}/exports/`, body);
  }

  public editShareableUrl(
    bookId: string,
    shareableUrlId: string,
    body: IEditShareableUrlRequest,
  ): Promise<IShareableUrlWithBuiltUrl> {
    return this.patch(`books/${bookId}/shareable-urls/${shareableUrlId}`, body);
  }

  public revokeShareableUrl(
    bookId: string,
    shareableUrlId: string,
  ): Promise<void> {
    return this.post(`books/${bookId}/shareable-urls/${shareableUrlId}/revocations`);
  }

  public unrevokeShareableUrl(
    bookId: string,
    shareableUrlId: string,
  ): Promise<void> {
    return this.delete(`books/${bookId}/shareable-urls/${shareableUrlId}/revocations`);
  }

  public removeShareableUrl(
    bookId: string,
    shareableUrlId: string,
  ): Promise<void> {
    return this.delete(`books/${bookId}/shareable-urls/${shareableUrlId}`);
  }

  public getShareableUrlDetails(
    shareableUrlShortId: string,
  ): Promise<IShareableUrlWithBuiltUrl> {
    return this.get(`reader/${shareableUrlShortId}/shareable-urls/current`);
  }

  public getReaderBook(
    shareableUrlShortId: string,
  ): Promise<IReaderBook> {
    return this.get(`reader/${shareableUrlShortId}/book/details/`);
  }

  public getReaderBookOwner(
    shareableUrlShortId: string,
  ): Promise<IDisplayedCollaborator> {
    return this.get(`reader/${shareableUrlShortId}/book/owner`);
  }

  public getReaderRichTextReferences(
    shareableUrlShortId: string,
    contentId: string,
  ): Promise<IGetRichTextReferencesResponse> {
    return this.get(
      `/reader/${shareableUrlShortId}/book/details/content/${contentId}/references`,
    );
  }

  public getReaderRichTextEndnotes(
    shareableUrlShortId: string,
    contentId: string,
    richTextId: string,
  ): Promise<IGetEndnotesResponse> {
    return this.get(
      `/reader/${shareableUrlShortId}/book/details/content/${contentId}/rich-text/${richTextId}/endnotes`,
    );
  }

  public cancelBookExport(bookId: string, exportId: string): Promise<void> {
    return this.put(`/books/${bookId}/exports/${exportId}/cancellation`);
  }

  public cancelBookImport(importId: string): Promise<void> {
    return this.put(`/imports/${importId}/cancellation`);
  }

  public patchBookDetails(bookId: string, payload: IPatchBookDetailsRequest): Promise<void> {
    return this.patch(`/books/${bookId}/details`, payload);
  }

  public inviteCollaborator(
    {bookId, inviteeEmail, role}:
    {bookId: string; inviteeEmail: string; role: ResourcesRole},
  ): Promise<void> {
    return this.post(`books/${bookId}/collaborators/invitations`, {
      inviteeEmail,
      role,
    });
  }

  public acceptInvitation({bookId, invitationId}: IBaseInvitationRequest): Promise<void> {
    return this.post(`/invitations/${bookId}/${invitationId}/acceptance`);
  }

  public fetchInvitationInfo({bookId, invitationId}: IBaseInvitationRequest): Promise<IInvitationInfo> {
    return this.get(`/invitations/${bookId}/${invitationId}`);
  }

  public resendInvitationEmail({bookId, invitationId}: IBaseInvitationRequest): Promise<void> {
    return this.post(`/invitations/${bookId}/${invitationId}/email/resend`);
  }

  public revokeInvitation({bookId, invitationId}: IBaseInvitationRequest): Promise<void> {
    return this.delete(`/invitations/${bookId}/${invitationId}`);
  }

  public exchangeCodeForSsoToken(code: string): Promise<void> {
    return this.post('/auth/authorisation-token', {code});
  }

  public signOut(): Promise<void> {
    return this.delete('/auth/session/');
  }

  public getBillingPortalUrl(): Promise<string> {
    return this.get('/subscription/billing-portal/url');
  }

  public fetchCurrentSubscriptionInfo(): Promise<ISubscriptionInfoResponse> {
    return this.get('/subscription/current/billing-info');
  }

  public calculatePrice(request: ICalculatePriceRequest): Promise<ICalculatePriceResponse> {
    return this.post('/subscription/price/calculation', request);
  }

  public fetchSubscriptionPaymentMethodInfo(): Promise<IPaymentMethodInfo> {
    return this.get('/subscription/payment-method');
  }

  public submitTimings(request: ILoadingTimingRequest): Promise<void> {
    return this.post('/t/l', request);
  }

  @debug()
  protected override async sendRequest<T = any>(
    method: HttpMethod,
    path: string,
    options: RawAxiosRequestConfig = {},
  ): Promise<T> {
    path = this.normalizePath(path);
    options.baseURL = this.apiPath;
    options.withCredentials = true;
    const data = await super.sendRequest(method, path, options);
    parseDates(data);
    return data;
  }

  protected override validateResponse(response: AxiosResponse): void {
    // Check for the X-Reedsy header in the response. If it's not there, then the request
    // has been intercepted/blocked eg by a corporate firewall.
    const responseIsFromOurServer = !!response.headers[this.validationHeader.toLowerCase()];
    if (responseIsFromOurServer) return;

    // If the request was intercepted, just set a status of 401, and treat it as if the
    // user has not been authenticated.
    const error = new Error('Response is not from the Reedsy Studio API') as AxiosError;
    error.response = response;
    error.response.status = HTTPStatus.Unauthorized;
    throw error;
  }

  private normalizePath(path: string): string {
    return path.startsWith('/') ? path : `/${path}`;
  }
}
