<template>
  <rbe-price-info
    class="flex-top"
    :class="{compact}"
  >
    <rbe-price-info-section v-if="!hideDetails && calculatedPrice">
      <span class="header">
        Add-ons
      </span>
      <rbe-price-item
        v-for="selectedProduct in products"
        :key="selectedProduct.product"
        class="product-price"
      >
        <span>{{ selectedProduct.title }}</span>
        <SubscriptionPrice
          :price="calculatedPrice.products[selectedProduct.product].amount"
          :currency="currency"
        />
      </rbe-price-item>
    </rbe-price-info-section>

    <rbe-price-info-section class="price-confirmation">
      <slot />
      <template v-if="!hideDetails">
        <rbe-price-item v-if="calculatedPrice">
          <span class="header">Subtotal</span>
          <SubscriptionPrice
            :price="calculatedPrice.subtotal"
            :currency="currency"
          />
        </rbe-price-item>
        <rbe-price-item
          v-for="discount in discounts"
          :key="discount.title"
        >
          <span class="flex-center discount-title">
            {{ discount.title }}
            <VuiIconTick
              v-if="discount.amount"
              class="reedsy-accented"
            />
          </span>

          <SubscriptionPrice
            class="discount-price"
            :price="-discount.amount"
            :currency="currency"
          />
        </rbe-price-item>

        <hr v-if="loading || calculatedPrice">
      </template>
      <div>
        <rbe-price-item
          v-if="loading || calculatedPrice"
          class="price-headline"
        >
          <span>{{ compact ? 'Total' : 'Your subscription' }}</span>

          <div>
            <SubscriptionPrice
              v-if="discounted"
              class="before-discount"
              :price="calculatedPrice.subtotal"
              :currency="currency"
            />
            <SubscriptionPrice
              class="highlighted"
              :price="total"
              :currency="currency"
              :interval="interval"
            />
          </div>
        </rbe-price-item>
        <p
          ref="vat-info"
          class="vat-info"
        >
          <template v-if="vatAmount > 0">
            The applicable VAT amount of
            <SubscriptionPrice
              :price="vatAmount"
              :currency="currency"
              :interval="interval"
            />
            is included in the total price.
          </template>
          <template v-else>
            There’s no VAT charged on this subscription.
          </template>
        </p>
      </div>
      <span v-if="nextBillingDate">
        <span v-if="isTrial && trialDaysLeft">You have {{ trialDaysLeft }} days remaining of your free trial.</span>
        <span v-if="!hideNextBillingInfo">Your first billing date will be {{ nextBillingDate }}</span>
      </span>
    </rbe-price-info-section>
  </rbe-price-info>
</template>

<script lang="ts">
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {ICalculatePriceResponse, IDiscount} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import SubscriptionPrice from './subscription-price.vue';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {IBillingInterval} from '@reedsy/utils.subscription';
import TrialCountdownMixin from '@reedsy/studio.shared/mixins/subscriptions/trial-countdown';
import {isEmpty} from '@reedsy/utils.object';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {FEATURE_SUMMARIES} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';
import {IProductSummary} from '@reedsy/studio.shared/components/modals/components/subscription/features/product-summary.interface';

@Component({
  components: {
    SubscriptionPrice,
  },
})
export default class SubscriptionPriceBreakdown extends mixins(ClientSharedVue, TrialCountdownMixin) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @Prop({type: Object as PropType<ICalculatePriceResponse>, required: false})
  public calculatedPrice: ICalculatePriceResponse;

  @Prop({type: String})
  public nextBillingDate: string;

  @Prop({type: Boolean})
  public compact: boolean;

  @Prop({type: Boolean})
  public loading: boolean;

  @Prop({type: Boolean})
  public hideDetails: boolean;

  @Prop({type: Boolean})
  public hideNextBillingInfo: boolean;

  public get currency(): SupportedCurrency {
    return this.calculatedPrice?.currency;
  };

  public get interval(): IBillingInterval {
    return this.calculatedPrice?.interval;
  };

  public get isTrial(): boolean {
    return this.$subscription.isTrial;
  };

  public get vatAmount(): number {
    return this.calculatedPrice?.vatAmount || 0;
  }

  public get discounts(): IDiscount[] {
    if (!this.calculatedPrice) return [];

    const hasDiscounts = !isEmpty(this.calculatedPrice.discounts);
    return hasDiscounts ? this.calculatedPrice.discounts : [
      {title: 'Discounts', amount: 0},
    ];
  }

  public get products(): IProductSummary[] {
    return FEATURE_SUMMARIES.filter(({product}) => this.calculatedPrice.products[product]);
  }

  public get discounted(): boolean {
    if (!this.calculatedPrice) return false;
    return this.calculatedPrice.total !== this.calculatedPrice.subtotal;
  }

  public get total(): number {
    return this.calculatedPrice?.total;
  }
}
</script>

<style lang="scss" scoped>
rbe-price-info {
  gap: $space-base;
  flex-grow: 1;

  rbe-price-info-section {
    gap: $space-sm;
    display: flex;
    flex-direction: column;

    &.price-confirmation {
      margin-top: auto;
    }
  }

  hr {
    color: var(--reedsy-dividerColor);
    width: 100%;
    height: $border-width;
  }

  .header {
    @include font-family($controls, bold);
  }

  rbe-price-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    :deep(.skeleton-loader) {
      max-width: 5rem;
    }

    &.price-headline {
      @include font-family($controls, bold);

      font-size: $font-size-lg;

      :deep(.skeleton-loader) {
        width: 6.5rem;
      }

      .highlighted {
        color: var(--local-color-default);
      }
    }

    &.product-price {
      margin-bottom: $space-xs;
      padding-bottom: $space-xs;
      border-bottom: $border;
    }

    .discount-title {
      gap: $space-sm;
    }

    .discount-price {
      color: var(--reedsy-form-info-textColor);
    }
  }

  .before-discount {
    color: var(--reedsy-form-info-textColor);
    margin-right: $space-xs;
    text-decoration: line-through;
  }

  .vat-info {
    font-size: $font-size-xs;
    color: var(--reedsy-form-info-textColor);
  }

  &.compact {
    hr {
      height: 0.1875rem;
    }
  }
}
</style>
