import {deepFreeze} from '@reedsy/utils.object';
import {IProductSummary} from './product-summary.interface';
import {SubscriptionProduct} from '@reedsy/utils.subscription';

export const FEATURE_SUMMARIES: ReadonlyArray<IProductSummary> = deepFreeze([
  {
    product: 'writing',
    title: 'Craft',
    features: [
      '⏳ Unlimited history',
      '📊 Advanced stats',
      '🎯 Custom goals',
      '☑️ Daily check-ins',
      '🌒 Dark mode',
    ],
  },
  {
    product: 'outlining',
    title: 'Outline',
    features: [
      '♾️ Unlimited Boards',
      '📋 Unlimited Notes with Attributes',
      '📌 Pinned notes',
      '👀 List view',
      '🌒 Dark mode',
    ],
  },
  {
    product: null,
    title: 'Basic',
    features: [
      '30-day history',
      'Basic stats',
      'One manuscript Goal',
      'Writing features',
      'Typeset to PDF and EPUB',
      'Planning Boards limited to card view',
      'Unlimited devices',
    ],
  },
]);

export const titleByProduct = FEATURE_SUMMARIES.reduce((titles, summary) => {
  if (summary.product) titles[summary.product] = summary.title;
  return titles;
}, {} as Record<SubscriptionProduct, string>);
