<template>
  <div>
    <PremiumBanner />
    <VuiTopMenu
      home-title="Reedsy"
      :home-url="homeUrl"
      app-name="studio"
      class="top-bar"
      :menu-options="menuOptions"
      @menu-option-click="handleMenuOptionClick"
    >
      <template #left>
        <transition
          name="fade"
          mode="out-in"
        >
          <TopBarButton
            v-if="$layout.mobileMenuCanBeShown"
            class="toggle-left-sidebar-button transparent"
            icon="VuiIconMenu"
            @click="$layout.toggleMobileMenu()"
          />
          <img
            v-else
            class="parrot-image"
            src="@/assets/parrot.png"
            alt="Reedsy Studio"
          >
        </transition>
      </template>
      <template #right>
        <UserSettingsMenu />
      </template>
    </VuiTopMenu>
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {config} from '@reedsy/studio.shared/config';
import UserSettingsMenu from '@reedsy/studio.home.bookshelf/components/user-settings-menu/user-settings-menu.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import TopBarButton from './top-bar-button.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import {IMenuOption} from './i-menu-option';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {objectKeys} from '@reedsy/utils.object';
import PremiumBanner from '@reedsy/studio.home.bookshelf/components/top-bar/banner/premium-banner.vue';

const MENU_OPTIONS = Object.freeze({
  help: {
    text: 'Help',
  },
} as const satisfies Record<string, Omit<IMenuOption<any>, 'id'>>);

type IMenuOptionsId = keyof typeof MENU_OPTIONS;
type IOptionHandler = (option: IMenuOption<IMenuOptionsId>) => void;

@Component({
  components: {
    PremiumBanner,
    UserSettingsMenu,
    TopBarButton,
  },
})
export default class TopBar extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public readonly config = config;

  private readonly handlersMap: Record<IMenuOptionsId, IOptionHandler> = {
    help: this.openHelpModal,
  } as const satisfies Record<IMenuOptionsId, IOptionHandler>;

  public get menuOptions(): IMenuOption<IMenuOptionsId>[] {
    return objectKeys(MENU_OPTIONS)
      .map((id) => ({id, ...MENU_OPTIONS[id]}));
  }

  public get homeUrl(): string {
    return config.app.home.origin;
  }

  public handleMenuOptionClick(option: IMenuOption<IMenuOptionsId>): void {
    this.handlersMap[option.id](option);
  }

  public openHelpModal(): void {
    this.$modals.open('HelpModal');
  }
}
</script>

<style lang="scss">
.top-bar .apps-dropdown {
  z-index: $z-index-top-bar-dropdown;
}
</style>

<style lang="scss" scoped>
.top-bar {
  .parrot-image {
    margin-right: $space-md;
    height: 2.3rem;
    width: auto;
  }

  .toggle-left-sidebar-button {
    width: auto;
    padding-right: $space-md;
  }
}
</style>
