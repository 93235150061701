
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {DiscountType, getDiscountPercentage} from './discounts';

@Component({})
export default class BundleDiscountAmount extends ClientSharedVue {
  public get discountAmount(): number {
    return getDiscountPercentage(DiscountType.Bundle);
  }
}
