import {Container, interfaces} from 'inversify';
import {TypedContainer} from '@reedsy/utils.inversify';
import {config} from '@reedsy/studio.shared/config';
import RedirectingApi from '@reedsy/studio.shared/services/api/redirecting-api';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import Navigation from '@reedsy/studio.shared/services/navigation';
import StoreWrapper from '@reedsy/studio.shared/store/store-wrapper';
import {$getLazyDecorators} from '@reedsy/utils.inversify';
import {StoreListener} from '@reedsy/studio.shared/store/helpers/store-listener';
import {AnalyticsService} from './services/analytics';
import {StudioAnalyticsManager} from './services/analytics/studio-analytics-manager';
import {ISharedStoreMapping, SHARED_STORE_FACTORY_MAPPING} from './store/store-mapping';
import {bindModules} from '@reedsy/studio.shared/store/bind-modules';
import {PLUGINS} from './store/plugins';
import {SharedBinding, SharedBindings} from './types';
import {TypedDecorator} from '@reedsy/utils.types';
import {DIRECTIVES} from './directives/factories';
import {SubscriptionModalService} from './services/subscriptions/subscription-modal-service';
import {SpotlightTour} from './services/spotlight-tour/spotlight-tour';
import {TOUR_ROUTES} from './services/spotlight-tour/tour-routes';
import {Stripe} from '@stripe/stripe-js';
import {WebSocketFactory} from './services/ws/websocket-factory';
import {StripeLoader} from './services/stripe/load-stripe';
import {TimingReporter} from '@reedsy/studio.shared/utils/timing/timing-reporter';

export const clientSharedContainer: TypedContainer<SharedBindings> = new Container();

clientSharedContainer.bind('Config').toConstantValue(config);

bindModules(clientSharedContainer, SHARED_STORE_FACTORY_MAPPING);

clientSharedContainer.bind('StoreWrapper')
  .to(StoreWrapper)
  .inSingletonScope();

clientSharedContainer.bind('Store')
  .toDynamicValue(({container}) => {
    const wrapper = getBinding('StoreWrapper', container);
    return wrapper.store;
  });

clientSharedContainer.bind('StoreListener').to(StoreListener);

clientSharedContainer.bind('Api')
  .to(RedirectingApi)
  .inSingletonScope()
  .whenTargetIsDefault();

clientSharedContainer.bind('Navigation')
  .to(Navigation)
  .inSingletonScope();

clientSharedContainer.bind('LoggerFactory')
  .toConstantValue(loggerFactory);

clientSharedContainer.bind('ModalsData')
  .toConstantValue({});

clientSharedContainer.bind('SubscriptionModal')
  .to(SubscriptionModalService);

clientSharedContainer.bind('StudioAnalyticsManager')
  .to(StudioAnalyticsManager)
  .inSingletonScope();

clientSharedContainer.bind('WebSocketFactory').to(WebSocketFactory);

clientSharedContainer.bind('Analytics').to(AnalyticsService);
clientSharedContainer.bind('SpotlightTour').to(SpotlightTour);
clientSharedContainer.bind('TourRoutes').toConstantValue(TOUR_ROUTES);
clientSharedContainer.bind('StripeProvider')
  .toProvider(() => {
    let stripe: Promise<Stripe> = null;
    return () => stripe ||= StripeLoader.load();
  });

clientSharedContainer.bind('TimingReporter').to(TimingReporter);
clientSharedContainer.bind('DocPaywallInfoMap').toConstantValue(new WeakMap());

PLUGINS.forEach((plugin) => clientSharedContainer.bind('SharedStorePlugins').to(plugin));
DIRECTIVES.forEach((directive) => clientSharedContainer.bind('Directive').to(directive));

function getBinding<K extends SharedBinding, T extends SharedBindings[K]>(
  serviceIdentifier: K,
  container: interfaces.Container,
): T {
  return container.get(serviceIdentifier);
}

export const {$lazyInject, $lazyInjectNamed} = $getLazyDecorators(clientSharedContainer);
export function $lazyInjectStore<K extends keyof ISharedStoreMapping>(
  key: K,
): TypedDecorator<ISharedStoreMapping[K]> {
  return $lazyInjectNamed('StoreModule', key);
}
