<template>
  <rbe-subscription-period class="flex-justified">
    <label v-if="!disableMonthly">
      <PeriodSummary
        class="period-option"
        :selected="!billAnnually"
        interval="month"
      />
      <input
        ref="month-input"
        v-model="billAnnually"
        type="radio"
        :value="false"
        aria-label="Monthly"
      >
    </label>

    <label>
      <PeriodSummary
        class="period-option"
        :selected="billAnnually"
        interval="year"
      />
      <input
        ref="year-input"
        v-model="billAnnually"
        type="radio"
        :value="true"
        aria-label="Annually"
      >
    </label>
  </rbe-subscription-period>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PeriodSummary from './period-summary.vue';
import {IBillingInterval} from '@reedsy/utils.subscription';

@Component({
  components: {PeriodSummary},
})
export default class BillingFrequencySelector extends ClientSharedVue {
  @Model({type: String, required: true})
  public interval: IBillingInterval;

  @Prop({type: Boolean, default: false})
  public disableMonthly: boolean;

  public get billAnnually(): boolean {
    return this.interval === 'year';
  }

  public set billAnnually(billAnnually: boolean) {
    this.interval = billAnnually ? 'year' : 'month';
  }
}
</script>

<style lang="scss" scoped>
rbe-subscription-period {
  gap: $space-sm;

  input[type='radio'] {
    display: none;
  }

  label {
    display: contents;
  }

  .period-option {
    flex-grow: 1;
  }
}
</style>
