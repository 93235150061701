
import {Component, Prop, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {HTMLImage} from '@reedsy/studio.shared/utils/html/html-image';
import {getInitials} from '@reedsy/studio.isomorphic/utils/get-initials';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import {CSSProperties, PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {INITIALS_PLACEHOLDER} from '@reedsy/studio.shared/components/avatar/initials-placeholder';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

const logger = loggerFactory.create('Avatar');
@Component({
  components: {
    PremiumIcon,
  },
})
export default class Avatar extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public readonly $subscription: SharedSubscriptionModule;

  @$lazyInjectStore(SharedStoreName.User)
  public readonly $user: SharedUserModule;

  @Prop({type: Boolean, default: false})
  public activityIndicator: boolean;

  @Prop({type: Boolean, default: false})
  public active: boolean;

  @Prop({type: Object as PropType<IUserInfo>, default: null})
  public userInfo: IUserInfo;

  @Prop({type: Boolean, default: false})
  public placeholder: boolean;

  @Prop({type: Boolean, default: false})
  public noBadge: boolean;

  @Prop({type: String, default: 'xs'})
  public badgeSize: string;

  public avatarSrc = '';

  public get name(): string {
    return this.userInfo?.name || 'Anonymous';
  }

  public get initials(): string {
    return getInitials(
      this.userInfo.firstName,
      this.userInfo.lastName,
    ) || INITIALS_PLACEHOLDER;
  }

  public get color(): string {
    return this.userInfo.color;
  }

  public get avatarStyles(): CSSProperties {
    if (this.placeholder) return {};

    return {
      background: this.color,
      borderColor: this.hasPremiumBadge ? undefined : this.color,
    };
  }

  public get isCurrentUser(): boolean {
    return this.$user.id === this.userInfo?._id;
  }

  public get hasPremiumBadge(): boolean {
    if (!this.isCurrentUser) return false;
    return !this.noBadge && this.$subscription.hasAnyPaidFeature;
  }

  @Watch('userInfo')
  public async watchUserInfo(): Promise<void> {
    await this.loadAvatar();
  }

  public async mounted(): Promise<void> {
    await this.loadAvatar();
  }

  public async loadAvatar(): Promise<void> {
    const avatarSrc = this.userInfo?.avatarUrl;
    if (!avatarSrc) return;

    try {
      await HTMLImage.fetch(avatarSrc);
      this.avatarSrc = avatarSrc;
    } catch (e) {
      // Just swallow image load errors. They're benign, and we shouldn't
      // alert if an Avatar can't be loaded.
      logger.debug(e);
    }
  }
}
