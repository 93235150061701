import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiDropdown = _resolveComponent("VuiDropdown")!

  return (_openBlock(), _createElementBlock("rbe-currency-selector", _hoisted_1, [
    _cache[1] || (_cache[1] = _createTextVNode("\n    Switch currency\n    ")),
    _createVNode(_component_VuiDropdown, {
      ref: "dropdown",
      modelValue: _ctx.selectedCurrency,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCurrency) = $event)),
      options: _ctx.currenciesOptions
    }, null, 8, ["modelValue", "options"])
  ]))
}