<template>
  <Panel
    title="Notification"
    class="book-notifications-settings-panel"
  >
    <PanelBodyTitle class="no-top-space">
      Writing goals

      <template #premium-info>
        <PremiumInfo :paid-feature="paidFeatures.goals">
          {{ premiumDescriptions.goals }}
        </PremiumInfo>
      </template>
    </PanelBodyTitle>

    <ItemsBox
      v-if="hasGoals"
    >
      <GoalToggle
        v-for="goal in bookUserGoals"
        :key="goal._id"
        :goal="goal"
      />
    </ItemsBox>
    <p
      v-else
      class="no-goals"
    >
      No user goals set for the book
    </p>

    <PanelBodyTitle ref="check-in-title">
      Check-in
      <template #premium-info>
        <PremiumInfo :paid-feature="paidFeatures.checkIns">
          {{ premiumDescriptions.checkIn }}
        </PremiumInfo>
      </template>
    </PanelBodyTitle>
    <ItemsBox>
      <template v-if="isLoadingUserBookSettings">
        <rbe-toggle-loader>
          <p>Reminder to check-in after writing</p>
          <SkeletonLoader class="loader" />
        </rbe-toggle-loader>
      </template>
      <VuiToggle
        v-else
        ref="check-in-toggler"
        v-model="checkInNotificationEnabled"
        label="Reminder to check-in after writing"
        class="label-left spread"
        :disabled="!subscription.hasFeature(paidFeatures.checkIns) || null"
      />
    </ItemsBox>
    <p class="more-settings">
      You can find more notification preferences in the
      <!-- We cannot have a new line as the dot should be straight after settings without space -->
      <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
      <RouterLink :to="userNotificationsSettingsRoute">user settings page</RouterLink>.
    </p>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {UserWordCountGoalsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-word-count-goals/user-word-count-goals';
import {IUserWordCountGoal} from '@reedsy/reedsy-sharedb/lib/common/word-count-goal/user-word-count-goal';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import PanelBodyTitle from '@reedsy/studio.shared/components/panel/panel-body-title.vue';
import GoalToggle from './goal-toggle.vue';
import ItemsBox from '@reedsy/studio.shared/components/items-box.vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {RouteLocationRaw} from 'vue-router';
import {UserBookSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-book-settings';
import {BookActionsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-actions/book-actions';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {PREMIUM_FEATURES_DESCRIPTIONS} from '@reedsy/studio.shared/literals/premium-feature-descriptions';
import {IUserBookSettings} from '@reedsy/reedsy-sharedb/lib/common/user-book-settings/user-book-settings';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {IPaidFeature} from '@reedsy/utils.subscription';
import PremiumInfo from '@reedsy/studio.shared/components/subscriptions/premium-info.vue';

@Component({
  components: {
    Panel,
    GoalToggle,
    PanelBodyTitle,
    ItemsBox,
    SkeletonLoader,
    PremiumInfo,
  },
})
export default class BookNotificationsSettingsPanel extends BookshelfVue {
  @$lazyInjectStore(StoreName.UserWordCountGoals)
  public readonly userGoals: UserWordCountGoalsModule;

  @$lazyInjectStore(StoreName.UserBookSettings)
  public readonly userBookSettings: UserBookSettingsModule;

  @$lazyInjectStore(StoreName.BookActions)
  public readonly bookActions: BookActionsModule;

  @$lazyInjectStore(StoreName.CurrentBook)
  public readonly book: CurrentBookModule;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public readonly subscription: SharedSubscriptionModule;

  public readonly paidFeatures = {
    checkIns: 'checkIns',
    goals: 'userWordCountGoals',
  } as const satisfies Record<string, IPaidFeature>;

  public readonly premiumDescriptions = PREMIUM_FEATURES_DESCRIPTIONS;

  public readonly userNotificationsSettingsRoute: RouteLocationRaw = {
    name: BookshelfRouteName.GeneralAccountSettings,
  };

  public get bookSettings(): IUserBookSettings {
    return this.userBookSettings.forBook(this.book.id);
  }

  public get isLoadingUserBookSettings(): boolean {
    return !this.bookSettings;
  }

  public get checkInNotificationEnabled(): boolean {
    return this.bookSettings.notifications.checkIn;
  }

  public set checkInNotificationEnabled(value: boolean) {
    this.bookActions.toggleCheckInNotifications({
      bookId: this.book.id,
      enable: value,
    });
  }

  public get bookUserGoals(): IUserWordCountGoal[] {
    return this.userGoals.forBook(this.book.id);
  }

  public get hasGoals(): boolean {
    return !!this.bookUserGoals.length;
  }
}
</script>

<style lang="scss" scoped>
.book-notifications-settings-panel {
  margin-top: $space-xl;

  .no-goals {
    font-style: italic;
    color: var(--reedsy-form-info-textColor);
  }

  .more-settings {
    margin-top: $space-base;

    a {
      text-decoration: underline;
    }
  }

  rbe-toggle-loader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-base;
    width: 100%;
    box-sizing: border-box;

    .loader {
      width: 10%;
      height: 1.75rem;
    }
  }
}
</style>
