import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumIcon = _resolveComponent("PremiumIcon")!
  const _component_VuiAccountDropdown = _resolveComponent("VuiAccountDropdown")!

  return (_openBlock(), _createBlock(_component_VuiAccountDropdown, {
    class: _normalizeClass(["user-settings-menu", {'has-paid-features': _ctx.hasAnyPaidFeature}]),
    options: _ctx.options,
    "user-firstname": _ctx.user.firstName,
    "user-lastname": _ctx.user.lastName,
    "user-avatar": _ctx.user.avatarUrl,
    style: _normalizeStyle({'--vui-accountDropdown-initials-bgColor': _ctx.user.color})
  }, _createSlots({ _: 2 }, [
    (_ctx.hasAnyPaidFeature)
      ? {
          name: "avatar-badge",
          fn: _withCtx(() => [
            _createVNode(_component_PremiumIcon, { size: "xs" })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["class", "options", "user-firstname", "user-lastname", "user-avatar", "style"]))
}