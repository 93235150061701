import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumAdBanner = _resolveComponent("PremiumAdBanner")!
  const _component_TopBanner = _resolveComponent("TopBanner")!

  return (_openBlock(), _createBlock(_component_TopBanner, null, {
    default: _withCtx(() => [
      _createVNode(_component_PremiumAdBanner, { class: "high-contrast with-badge-padding" })
    ]),
    _: 1
  }))
}